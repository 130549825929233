<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Thomann Diccionario</div>

                <b-button class="float-right mr-1" @click="showModalDeleteRegistro()" variant="danger" size="sm" :disabled="!selectedRow">
                    <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>

                <b-button class="float-right mr-1" @click="showModalEditRegistro()" variant="warning" size="sm" :disabled="!selectedRow">
                    <b-icon icon="pencil" font-scale="1"></b-icon>
                </b-button>

                <b-button class="float-right mr-3" @click="showModalAgregarRegistro()" variant="primary" size="sm">
                    <b-icon icon="plus" font-scale="1"></b-icon>
                </b-button>
                
                
            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else>
                <b-table striped hover :items="tabla.data" :fields="tabla.fields" responsive="sm" select-mode="single" selectable @row-selected="selectRow">
                    <template #cell(activo)="data">
                        <span>{{ data.value? 'SI':'NO' }}</span>
                    </template>
                    <template #cell(disp_stock_str)="data">
                        <span>{{ data.value }}</span>
                    </template>
                    <template #cell(stock_dispo)="data">
                        <span>{{ data.value }}</span>
                    </template>
                    <template #cell(stock_proveedor)="data">
                        <span>{{ data.value }}</span>
                    </template>
                    <template #cell(updatedAt)="data">
                        <span style="font-size: small;">{{data.value}}</span>
                    </template>
                </b-table>
            </div>
        </b-card>
    </div>  
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            loading_force : false,
            selectedRow:null,
            tabla: {
                fields: [
                    {
                        key: 'activo',
                        label: 'Status',
                        sortable: true,
                    },
                    {
                        key: 'disp_stock_str',
                        label: 'Texto',
                        sortable: true
                    },
                    {
                        key: 'stock_dispo',
                        label: 'Stock Dispo',
                        sortable: true
                    },
                    {
                        key: 'stock_proveedor',
                        label: 'Stock Proveedor',
                        sortable: true,
                    },
                    {
                        key: 'updatedAt',
                        label: 'Modificado',
                        sortable: true,
                    }
                ],
                data:[]
            },
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getRegistrosDiccionario(){
            try {
                let response = await this.$http.get('scraper/thomann/diccionario', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener diccionario');
                this.tabla.data = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        selectRow(item){
            this.selectedRow = item[0];
        },
        async showModalEditRegistro(){
            console.log('showModalEditRegistro',this.selectedRow);
            const { value: formValues } = await this.$swal.fire({
                title: 'Agregar Registro',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Guardar`,
                cancelButtonText: `Cancelar`,
                reverseButtons: true,
                html:`  
                    <div class="mt-2">
                        <div class="mb-3">
                            <label for="add_registro_texto" class="form-label">Texto</label>
                            <input type="text" class="form-control" placeholder="Texto" id="add_registro_texto" value="${this.selectedRow.disp_stock_str}">
                        </div>
                        <div class="mb-3">
                            <label for="add_registro_dias" class="form-label">Stock Dispo</label>
                            <input type="number" class="form-control" placeholder="Dias" id="add_registro_dias" value="${this.selectedRow.stock_dispo}">
                        </div>
                        <div class="mb-3">
                            <label for="add_registro_unidades" class="form-label">Stock Proveedor</label>
                            <input type="number" class="form-control" placeholder="Unidades" id="add_registro_unidades" value="${this.selectedRow.stock_proveedor}">
                        </div>
                        <div class="mb-3">
                            <label for="add_registro_status" class="form-label">Status</label>
                            <select class="form-select" id="add_registro_status">
                                <option value="1" ${this.selectedRow.activo ? 'selected' : ''}>Si</option>
                                <option value="0" ${!this.selectedRow.activo ? 'selected' : ''}>No</option>
                            </select>
                        </div>
                    </div>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        texto: document.getElementById('add_registro_texto').value,
                        dias: document.getElementById('add_registro_dias').value,
                        unidades: document.getElementById('add_registro_unidades').value,
                        status: document.getElementById('add_registro_status').value
                    }
                }
            })

            if (formValues) {
                this.editRegistro(formValues)
            }
        },
        async showModalDeleteRegistro(){
            this.$swal.fire({
                title: 'Esta seguro de borrar el registro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Borrar`,
                cancelButtonText: `Cancelar`,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRegistro()
                }
            })
        },
        async showModalAgregarRegistro(){
            const { value: formValues } = await this.$swal.fire({
                title: 'Agregar Registro',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Guardar`,
                cancelButtonText: `Cancelar`,
                reverseButtons: true,
                html:`  
                    <div class="mt-2">
                        <div class="mb-3">
                            <label for="add_registro_texto" class="form-label">Texto</label>
                            <input type="text" class="form-control" placeholder="Texto" id="add_registro_texto">
                        </div>
                        <div class="mb-3">
                            <label for="add_registro_dias" class="form-label">Stock Dispo</label>
                            <input type="number" class="form-control" placeholder="Dias" id="add_registro_dias">
                        </div>
                        <div class="mb-3">
                            <label for="add_registro_unidades" class="form-label">Stock Proveedor</label>
                            <input type="number" class="form-control" placeholder="Unidades" id="add_registro_unidades">
                        </div>
                    </div>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        texto: document.getElementById('add_registro_texto').value,
                        dias: document.getElementById('add_registro_dias').value,
                        unidades: document.getElementById('add_registro_unidades').value
                    }
                }
            })

            if (formValues) {
                this.addRegistro(formValues)
            }
        },
        async addRegistro(formValues){
            try {
                //Verifico la info
                if(!formValues.texto) throw new Error('Ingrese un texto');
                if(!formValues.dias) throw new Error('Ingrese la cantidad de dias');
                if(!formValues.unidades) throw new Error('Ingrese las unidades');

                let response = await this.$http.post('scraper/thomann/diccionario',formValues, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al agregar registro');
                //console.log(response.body);

                await this.getRegistrosDiccionario();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'El registro fue agregado',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async editRegistro(formValues){
            try {
                //Verifico la info
                if(!formValues.texto) throw new Error('Ingrese un texto');
                if(!formValues.dias) throw new Error('Ingrese la cantidad de dias');
                if(!formValues.unidades) throw new Error('Ingrese las unidades');

                let response = await this.$http.put('scraper/thomann/diccionario/'+this.selectedRow.id,formValues, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al agregar registro');
                console.log(response.body);

                await this.getRegistrosDiccionario();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'El registro fue editado',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async deleteRegistro(){
            try {
                let response = await this.$http.delete('scraper/thomann/diccionario/'+this.selectedRow.id, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al agregar registro');
                console.log(response.body);

                await this.getRegistrosDiccionario();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'El registro fue borrado',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        }
    },
    
    async beforeMount(){
        this.loading = true;
        await this.getRegistrosDiccionario();
        this.loading = false;
    }
}
</script>

<style>
.table.b-table>tbody>.table-active, .table.b-table>tbody>.table-active>td, .table.b-table>tbody>.table-active>th {
    background-color: #b8daff;
}
</style>