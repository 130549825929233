import Vue from 'vue';
import VueRouter from 'vue-router';

//Components
import LogIn from '../components/LogIn/LogIn';
import Home from '../components/Home/Home';
import Perfil from '../components/Perfil/Perfil';
import Error from '../components/Misc/Error';
import Forbidden from '../components/Misc/Forbidden';


import AdminProveedores from '../components/Proveedores/index';
import ListProveedores from '../components/Proveedores/ListProveedores';
import ListLinksThomann from '../components/Proveedores/thomann/ListaLinks';
import ListItemsThomann from '../components/Proveedores/thomann/ListaItems';
import DiccionarioThomann from '../components/Proveedores/thomann/Diccionario';

import AdminProductos from '../components/Productos/index';
import ListaProductos from '../components/Productos/ListaProductos';
import RelevarProducto from '../components/Productos/RelevarProducto';
import RelevarAttrsProducto from '../components/Productos/RelevarAttrsProducto';

//Middleware
import VueRouteMiddleware from 'vue-route-middleware';
import Auth from './middelwares/auth';
import Guest from './middelwares/guest';
import Permisos from './middelwares/permisos';

Vue.use(VueRouter);

const routes = [
    { path: '/login', name: 'login', component: LogIn, meta: { middleware: Guest } },
    { path: '/', name: 'root', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/home', name: 'home', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/perfil', name: 'perfil', component: Perfil, meta: { middleware: [Auth] } },
    {
        path: '/proveedores',
        component: AdminProveedores,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listProveedores', component: ListProveedores },
            { path: 'thomann/links', name: 'thomannListaLinks', component: ListLinksThomann },
            { path: 'thomann/items', name: 'thomannListaItems', component: ListItemsThomann },
            { path: 'thomann/diccionario', name: 'thomannDiccionario', component: DiccionarioThomann },
        ]
    },
    {
        path: '/productos',
        component: AdminProductos,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listProductos', component: ListaProductos },
            { path: '/:id/relevar', name: 'relevarProducto', component: RelevarProducto },
            { path: '/:id/relevar/atributos', name: 'relevarAttrsProducto', component: RelevarAttrsProducto }
        ]
    },
    { path: '/forbidden', name: 'forbidden', component: Forbidden, meta: { middleware: Auth } },
    { path: '*', name: 'error', component: Error, meta: { middleware: Auth } }
];

export const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach(VueRouteMiddleware());