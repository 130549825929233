<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">
                    Relevar Attrs Producto: {{producto ? producto.sku : 'Loading ...'}}
                </div>

                <!-- Boton Links a proveedores-->
                <b-dropdown 
                    text="Datos Prov." 
                    class="float-right ml-1" 
                    size="sm" 
                    variant="primary"
                    v-if="producto && producto.Articulos && producto.Articulos.length"
                >
                    <b-dropdown-item 
                        v-for="(articulo, index) in producto.Articulos"
                        :key="index"
                        :href="articulo.link_item_proveedor"
                        target="_blank"
                    >
                        Articulo {{index}}
                    </b-dropdown-item>
                </b-dropdown>

                <!-- Boton buscar datos producto en google-->
                <b-button 
                    v-if="producto"
                    variant="warning"
                    :href="'https://www.google.com/search?q='+producto.marca+'+'+producto.modelo"
                    target="_blank"
                    size="sm"
                    class="float-right ml-1"
                >
                    Buscar en Google
                </b-button>  

                <!-- Boton Relevar mas tarde-->
                <b-button 
                    v-if="producto"
                    variant="danger"
                    size="sm"
                    class="float-right ml-1"
                    @click="checkMarcarPendiente()"
                >
                    Pendiente
                </b-button> 

            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else class="mb-3">
                
                <!-- Datos Producto -->
                <b-card no-body class="mb-4">
                    <b-card-header header-tag="header" class="p-1">
                        <b-button block variant="info">Datos Producto</b-button>
                    </b-card-header>
                    <b-card-body>

                        <div class="row mb-3">
                            <!-- Marca -->
                            <div class="col-12 col-md-4">
                                <b-input-group prepend="Marca" size="sm">
                                    <b-form-input 
                                        disabled
                                        v-model="producto.marca"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Modelo -->
                            <div class="col-12 col-md-4">
                                <b-input-group prepend="Modelo" size="sm">
                                    <b-form-input 
                                        disabled
                                        v-model="producto.modelo"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Cantidad Paquete -->
                            <div class="col-12 col-md-4">
                                <b-input-group prepend="Cant Paq." size="sm">
                                    <b-form-input 
                                        disabled
                                        v-model="producto.catidad_paquete"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                        </div>

                        <div>
                            <b>Categorias:</b>
                            <span v-for="(categoria, index) in categorias" :key="index">
                                {{categoria.nombre}}
                                <span v-if="index != categorias.length - 1"> / </span>
                            </span>
                        </div>
                        

                    </b-card-body>  
                </b-card>

                <!-- Accordion Datos Relevar -->
                <template>
                    <div class="accordion" role="tablist">
                        <!-- Datos Obligatorios -->
                        <b-card no-body class="mb-2">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant="info">Datos Obligatorios</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <!-- Medidas -->
                                    <div>
                                        <div class="row mb-2">
                                            <div v-bind:class="[(datosObligatorios.medidas.optionSelect == 'OTRO') ? 'col-12 col-lg-5' : 'col-12 col-lg-8' ]">
                                                <b-input-group prepend="Medidas" size="sm">
                                                    <b-form-select 
                                                        v-model="datosObligatorios.medidas.optionSelect" 
                                                        @change="setMedidaFinal($event)"
                                                    >
                                                        <b-form-select-option :value="null" disabled>-- Seleccionar Medida Final --</b-form-select-option>
                                                        <b-form-select-option
                                                            v-for="(medida, index) in datosObligatorios.medidas.anteriores"
                                                            :key="'ANT-'+index"
                                                            :value="medida" 
                                                        >
                                                            {{ medida.type }} : {{ medida.x }}{{ medida.x_unit }}, {{ medida.y }}{{ medida.y_unit }}, {{ medida.z }}{{ medida.z_unit }}
                                                        </b-form-select-option>
                                                        <b-form-select-option
                                                            v-for="(medida, index) in datosObligatorios.medidas.items"
                                                            :key="'ITEM-'+index"
                                                            :value="medida" 
                                                        >
                                                            {{ medida.type }} : {{ medida.x }}{{ medida.x_unit }}, {{ medida.y }}{{ medida.y_unit }}, {{ medida.z }}{{ medida.z_unit }}
                                                        </b-form-select-option>
                                                        <b-form-select-option value="OTRO" >Otro</b-form-select-option>
                                                    </b-form-select>
                                                </b-input-group>
                                            </div>
                                            <div class="col-12 col-lg-3" v-show="datosObligatorios.medidas.optionSelect == 'OTRO'">
                                                <b-button-group size="sm" >
                                                    <b-button variant="secondary" @click="changeAllUnits('medidas','in')" class="btn-unit">"</b-button>
                                                    <b-button variant="secondary" @click="changeAllUnits('medidas','mm')" class="btn-unit">mm</b-button>
                                                    <b-button variant="secondary" @click="changeAllUnits('medidas','cm')" class="btn-unit">cm</b-button>
                                                </b-button-group>
                                            </div>
                                            <div class="col-12 col-lg-2">
                                                <b-input-group prepend="Aprox." size="sm" class="float-right">
                                                    <b-form-select 
                                                        v-model="datosObligatorios.medidas.aprox"
                                                        :options="arrBooleanOptions"
                                                    >
                                                    </b-form-select>
                                                </b-input-group>
                                            </div>
                                            <div class="col-12 col-lg-2">
                                                <b-button 
                                                    variant="outline-primary"
                                                    :href="getUrlSearch('medida')"
                                                    target="_blank"
                                                    size="sm"
                                                    class="float-right"
                                                >Buscar</b-button>
                                            </div>
                                        </div>  
                                        <div class="row">
                                            <div class="col-12 col-lg-10 row" v-show="datosObligatorios.medidas.optionSelect == 'OTRO'">

                                                <!-- Ancho -->
                                                <div class="col-12 col-lg-4">
                                                    <b-input-group prepend="Ancho" size="sm">
                                                        <b-form-input 
                                                            type="number"
                                                            aria-label="Valor"
                                                            v-model="datosObligatorios.medidas.selected.x"
                                                        ></b-form-input>
                                                        <b-form-select 
                                                            v-model="datosObligatorios.medidas.selected.x_unit"
                                                            :options="datosObligatorios.medidas.arrUnidades"
                                                        >
                                                        </b-form-select>
                                                    </b-input-group>
                                                </div>
                                                <!-- Alto -->
                                                <div class="col-12 col-lg-4">
                                                    <b-input-group prepend="Alto" size="sm">
                                                        <b-form-input 
                                                            type="number"
                                                            aria-label="Valor"
                                                            v-model="datosObligatorios.medidas.selected.y"
                                                        ></b-form-input>
                                                        <b-form-select 
                                                            v-model="datosObligatorios.medidas.selected.y_unit"
                                                            :options="datosObligatorios.medidas.arrUnidades"
                                                        >
                                                        </b-form-select>
                                                    </b-input-group>
                                                </div>
                                                <!-- Profundo -->
                                                <div class="col-12 col-lg-4">
                                                    <b-input-group prepend="Profundo" size="sm">
                                                        <b-form-input 
                                                            type="number"
                                                            aria-label="Valor"
                                                            v-model="datosObligatorios.medidas.selected.z"
                                                        ></b-form-input>
                                                        <b-form-select 
                                                            v-model="datosObligatorios.medidas.selected.z_unit"
                                                            :options="datosObligatorios.medidas.arrUnidades"
                                                        >
                                                        </b-form-select>
                                                    </b-input-group>
                                                </div>

                                            </div>    
                                        </div>       
                                    </div>   

                                    <hr>

                                    <!-- Pesos -->
                                    <div class="row mb-2">
                                        <div v-bind:class="[(datosObligatorios.pesos.optionSelect == 'OTRO') ? 'col-12 col-lg-5' : 'col-12 col-lg-8' ]">
                                            <b-input-group prepend="Pesos" class="" size="sm">
                                                <b-form-select 
                                                    v-model="datosObligatorios.pesos.optionSelect" 
                                                    @change="setPesoFinal($event)"
                                                >
                                                    <b-form-select-option :value="null" disabled>-- Seleccionar Peso Final --</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="(peso, index) in datosObligatorios.pesos.anteriores"
                                                        :key="'ANT-'+index"
                                                        :value="peso" 
                                                    >
                                                        {{ peso.type }} : {{ peso.value }}{{ peso.unit }}
                                                    </b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="(peso, index) in datosObligatorios.pesos.items"
                                                        :key="'ITEM-'+index"
                                                        :value="peso" 
                                                    >
                                                        {{ peso.type }} : {{ peso.value }}{{ peso.unit }}
                                                    </b-form-select-option>
                                                    <b-form-select-option value="OTRO" >Otro</b-form-select-option>
                                                </b-form-select>
                                            </b-input-group>
                                        </div>
                                        <div class="col-12 col-lg-3" v-show="datosObligatorios.pesos.optionSelect == 'OTRO'">
                                            <!-- Botones -->
                                            <b-button-group size="sm mr-1">
                                                <b-button variant="secondary" @click="changeAllUnits('peso','g')" class="btn-unit">g</b-button>
                                                <b-button variant="secondary" @click="changeAllUnits('peso','kg')" class="btn-unit">kg</b-button>
                                                <b-button variant="secondary" @click="changeAllUnits('peso','lb')" class="btn-unit">lb</b-button>
                                            </b-button-group>

                                        </div>
                                        <div class="col-12 col-lg-2">
                                            <b-input-group prepend="Aprox." size="sm" class="float-right">
                                                <b-form-select 
                                                    v-model="datosObligatorios.pesos.aprox"
                                                    :options="arrBooleanOptions"
                                                >
                                                </b-form-select>
                                            </b-input-group>
                                        </div>  
                                        <div class="col-12 col-lg-2">
                                            <b-button 
                                                variant="outline-primary"
                                                :href="getUrlSearch('peso')"
                                                target="_blank"
                                                size="sm"
                                                class="float-right"
                                            >Buscar</b-button>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-lg-10" v-show="datosObligatorios.pesos.optionSelect == 'OTRO'">
                                            <b-input-group prepend="Peso" size="sm">
                                                <b-form-input 
                                                    type="number"
                                                    aria-label="Valor"
                                                    v-model="datosObligatorios.pesos.selected.value"
                                                ></b-form-input>
                                                <b-form-select 
                                                    v-model="datosObligatorios.pesos.selected.unit"
                                                    :options="datosObligatorios.pesos.arrUnidades"
                                                >
                                                </b-form-select>
                                            </b-input-group>
                                        </div>   
                                    </div>
                                    <hr>

                                    <!-- Codigos -->
                                    <div class="row">
                                        <div v-bind:class="[(datosObligatorios.codigos.optionSelect == 'OTRO') ? 'col-5' : 'col-10' ]">
                                            <b-input-group prepend="Codigos" class="mb-2" size="sm">
                                                <b-form-select 
                                                    v-model="datosObligatorios.codigos.optionSelect" 
                                                    @change="setCodigoFinal($event)"
                                                >
                                                    <b-form-select-option :value="null" disabled>-- Seleccionar Codigo Final --</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="(codigo, index) in datosObligatorios.codigos.anteriores"
                                                        :key="'ANT-'+index"
                                                        :value="codigo" 
                                                    >
                                                        {{ codigo.type }} : {{ codigo.value }}
                                                    </b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="(codigo, index) in datosObligatorios.codigos.items"
                                                        :key="'ITEM-'+index"
                                                        :value="codigo" 
                                                    >
                                                        {{ codigo.type }} : {{ codigo.value }}
                                                    </b-form-select-option>
                                                    <b-form-select-option value="OTRO" >Otro</b-form-select-option>
                                                </b-form-select>
                                            </b-input-group>
                                        </div>
                                        <div class="col-5" v-show="datosObligatorios.codigos.optionSelect == 'OTRO'">
                                            <b-input-group prepend="Codigo" class="mb-2" size="sm">
                                                <b-form-input 
                                                    aria-label="Valor"
                                                    type="number"
                                                    v-model="datosObligatorios.codigos.selected.value"
                                                ></b-form-input>
                                            </b-input-group>
                                        </div>

                                        <div class="col-2">
                                            <b-button 
                                                variant="outline-primary"
                                                :href="getUrlCheckCodigo()"
                                                target="_blank"
                                                size="sm"
                                                class="float-right"
                                                v-if="this.datosObligatorios.codigos.selected.value"
                                            >Check</b-button>
                                            <b-button 
                                                variant="outline-primary"
                                                :href="getUrlSearch('codigo')"
                                                target="_blank"
                                                size="sm"
                                                class="float-right"
                                                v-else
                                            >Buscar</b-button>
                                        </div>
                                    </div>
                                    <hr>

                                    <!-- Flags y categoria-->
                                    <div class="row">
                                        <div class="col-5">
                                            <b-input-group prepend="Categoria Fiscal" class="mb-2" size="sm">
                                                <b-form-select 
                                                    v-model="datosObligatorios.categoriaFiscal.selected"
                                                    :options="datosObligatorios.categoriaFiscal.arrOpciones"
                                                >
                                                    <b-form-select-option :value="null" disabled>-- Seleccionar una categoria --</b-form-select-option>
                                                </b-form-select>
                                            </b-input-group>
                                        </div>

                                        <div class="col-7 row">
                                            <div class="col-4">
                                                <b-input-group prepend="Trafo" class="w-auto" size="sm">
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox 
                                                            switch 
                                                            class="mr-n2"
                                                            v-model="datosObligatorios.flags.trafo"
                                                            :checked="datosObligatorios.flags.trafo"
                                                        >
                                                            <span class="sr-only">Trafo</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </div>

                                            <div class="col-4">
                                                <b-input-group prepend="Baterias" class="w-auto" size="sm">
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox 
                                                            switch 
                                                            class="mr-n2"
                                                            v-model="datosObligatorios.flags.baterias"
                                                            :checked="datosObligatorios.flags.baterias"
                                                        >
                                                            <span class="sr-only">Baterias</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </div>
                                            
                                            <div class="col-4">
                                                <b-input-group prepend="Cable Alim." class="w-auto" size="sm">
                                                    <b-input-group-append is-text>
                                                        <b-form-checkbox 
                                                            switch 
                                                            class="mr-n2"
                                                            v-model="datosObligatorios.flags.cable"
                                                            :checked="datosObligatorios.flags.cable"
                                                        >
                                                            <span class="sr-only">Cable Alim.</span>
                                                        </b-form-checkbox>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-body>
                            </b-collapse>
                        </b-card>
                         <!-- Fotos -->
                        <b-card no-body class="mb-2">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-2 variant="info">Fotos</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                <b-card-body>

                                    <!-- Agregar Fotos -->
                                    <div class="row">
                                        <div class="col-7 mb-3">
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    v-model.trim="datosImagenes.aux_img_add"
                                                ></b-form-input>
                                                <b-input-group-append>
                                                    <b-button variant="primary" @click="agregarImgNueva()">Agregar</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>

                                        <div class="col-5 mb-3">
                                            <b-button 
                                                variant="outline-primary"
                                                :href="getUrlSearch('img')"
                                                target="_blank"
                                                size="sm"
                                                class="float-right"
                                            >Buscar Imagenes</b-button>
                                        </div>

                                        <div v-for="(url,index) in datosImagenes.agregadas" :key="index+'-add'" class="col-3 mb-2 text-center">
                                            <img 
                                                :src="url" 
                                                class="img-thumbnail mr-2 mb-2" 
                                                alt="imagen Producto" 
                                                style="max-width: 150px;cursor:pointer"
                                                v-bind:class="{'primary-img': datosImagenes.primaria == url,'selected-img': datosImagenes.selected.indexOf(url) != -1}"
                                                @click="toggleSelectedImg(url,null,$event)"
                                            >
                                            <div class="text-center">
                                                <small>{{checkProveedorImg(url) || 'Otro'}}</small>
                                            </div>
                                        </div>

                                    </div>

                                    <hr>
                                    <div>
                                        <div>Click -> Seleccionar foto</div>
                                        <div>Shift + Click -> Seleccionar foto primaria</div>
                                        <div>Ctrl + Click -> Zoom foto</div>
                                    </div>
                                    <hr>

                                    <!-- Fotos Guardadas-->
                                    <div class="row">
                                        <div v-for="(img,index) in datosImagenes.anteriores" :key="index" class="col-3 mb-2 text-center">
                                            <img 
                                                :src="img.path" 
                                                class="img-thumbnail mr-2 mb-2" 
                                                alt="imagen Producto" 
                                                style="max-width: 150px;cursor:pointer"
                                                v-bind:class="{'primary-img': datosImagenes.primaria == img.path,'selected-img': datosImagenes.selected.indexOf(img.path) != -1}"
                                                @click="toggleSelectedImg(img.path,img.original_url,$event)"
                                            >
                                            <div class="text-center">
                                                <small>{{checkProveedorImg(img.path,img.original_url) || 'Otro'}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <hr>

                                    <!-- Fotos Meli-->
                                    <div class="row">
                                        <div v-for="(url,index) in datosImagenes.items" :key="index+'-item'" class="col-3 mb-2 text-center">
                                            <img 
                                                :src="url" 
                                                class="img-thumbnail mr-2 mb-2"
                                                alt="imagen Producto" 
                                                style="max-width: 150px;cursor:pointer"
                                                v-bind:class="{'primary-img': datosImagenes.primaria == url, 'selected-img': datosImagenes.selected.indexOf(url) != -1}"
                                                @click="toggleSelectedImg(url,null,$event)"
                                            >
                                            <div>
                                                <small>{{checkProveedorImg(url) || 'Otro'}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <!-- Videos -->
                        <b-card no-body class="mb-2">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-3 variant="info">Videos</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                <b-card-body>

                                    <!-- Agregar Videos -->
                                    <div class="row">
                                        <div class="col-7 mb-3">
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    v-model.trim="datosVideos.aux_video_add"
                                                ></b-form-input>
                                                <b-input-group-append>
                                                    <b-button variant="primary" @click="agregarVideoNuevo()">Agregar</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>

                                        <div class="col-5 mb-3">
                                            <b-button 
                                                variant="outline-primary"
                                                :href="getUrlSearch('video')"
                                                target="_blank"
                                                size="sm"
                                                class="float-right"
                                            >Buscar</b-button>
                                        </div>
                                    </div>

                                    <!-- Videos agregados-->
                                    <b-input-group
                                        v-for="(urlVideo,index) in datosVideos.agregadas" 
                                        :key="'VIDEO-ADD-'+index"
                                        class="mb-2"
                                        size="sm" 
                                    >
                                        <b-input-group-prepend is-text>
                                            <input 
                                                type="radio"
                                                @click="setVideoSelect(urlVideo)"
                                                :checked="urlVideo == datosVideos.selected"
                                            >
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :value="urlVideo"
                                            disabled
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button 
                                                size="sm" 
                                                text="Button" 
                                                variant="success"
                                                :href="urlVideo"
                                                target="_blank"
                                            >Ver</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    
                                    <!-- Videos Guardadas-->
                                    <b-input-group
                                        v-for="(urlVideo,index) in datosVideos.anteriores" 
                                        :key="'VIDEO-ANT-'+index"
                                        class="mb-2"
                                        size="sm" 
                                    >
                                        <b-input-group-prepend is-text>
                                            <input 
                                                type="radio"
                                                @click="setVideoSelect(urlVideo)"
                                                :checked="urlVideo == datosVideos.selected"
                                            >
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :value="urlVideo"
                                            disabled
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button 
                                                size="sm" 
                                                text="Button" 
                                                variant="success"
                                                :href="urlVideo"
                                                target="_blank"
                                            >Ver</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <!-- Videos Meli-->
                                    <b-input-group
                                        v-for="(urlVideo,index) in datosVideos.items" 
                                        :key="'VIDEO-ITEM-'+index"
                                        class="mb-2"
                                        size="sm" 
                                    >
                                        <b-input-group-prepend is-text>
                                            <input 
                                                type="radio"
                                                @click="setVideoSelect(urlVideo)"
                                                :checked="urlVideo == datosVideos.selected"
                                            >
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :value="urlVideo"
                                            disabled
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button 
                                                size="sm" 
                                                text="Button" 
                                                variant="success"
                                                :href="urlVideo"
                                                target="_blank"
                                            >Ver</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <!-- Atributos -->
                        <b-card no-body class="mb-2">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-4 variant="info">Atributos</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-tabs content-class="mt-3" align="center">
                                        <b-tab 
                                            v-for="(categoria, index) in datosAttr.categoriaMeli"
                                            :key="index"
                                            :title="categoria.idCategoria" 
                                            :active="index == 0"
                                        >
                                            <b-input-group prepend="Categoria Primaria" class="mb-4">
                                                <b-input-group-append is-text>
                                                    <b-form-checkbox 
                                                        switch 
                                                        class="mr-n2"
                                                        @change="changeCategoriaPrimaria(categoria.idCategoria)"
                                                        :checked="datosAttr.categoriaMeliPrimaria == categoria.idCategoria"
                                                    >
                                                        <span class="sr-only">Categoria Primaria</span>
                                                    </b-form-checkbox>
                                                </b-input-group-append>
                                            </b-input-group>

                                            <div class="row">
                                                <div v-for="(attr , index2) in categoria.attrs" 
                                                    :key="index2" 
                                                    class="col-6" 
                                                    v-bind:class="{'col-12': attr.name.length > 25,'d-none': !attr.datos.show}"
                                                    
                                                > 
                                                    <!-- Caso type string -->
                                                    <div v-if="attr.value_type == 'string'">

                                                        <!-- Si es un multi select -->
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            v-if="attr.values && attr.values.length > 0 && attr.tags.hasOwnProperty('multivalued')"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <b-form-select
                                                                v-model="attr.datos.id"
                                                                :options="getAttrOptions(attr.values)"
                                                                @change="setValuesToAttrOnSelectMultiple(attr)"
                                                                multiple
                                                            >
                                                            </b-form-select>
                                                        </b-input-group>
                                                            
                                                        <!-- Si es un simple select -->
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            v-else-if="attr.values && attr.values.length > 0 && !attr.tags.hasOwnProperty('multivalued')"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <b-form-select 
                                                                v-model="attr.datos.id"
                                                                @change="setValuesToAttrOnSelect(attr)"
                                                            >

                                                                <b-form-select-option :value="null" disabled>-- Seleccione una opcion --</b-form-select-option>
                                                                <b-form-select-option
                                                                    v-for="(option, index3) in getAttrOptions(attr.values)"
                                                                    :key="index3"
                                                                    :value="option.value"
                                                                    :title="option.title || null"  
                                                                >
                                                                    {{ option.text }}
                                                                </b-form-select-option>
                                                                <b-form-select-option value="OTRO" >Otro</b-form-select-option>
                                                            </b-form-select>

                                                            <b-form-input
                                                                v-if="attr.datos.id == 'OTRO'"
                                                                v-model="attr.datos.valueOtro"
                                                                placeholder="Ingrese un valor"
                                                            >
                                                            </b-form-input>
                                                        </b-input-group>
                                                            
                                                        <!-- Si es un simple string -->
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                            v-else
                                                        >
                                                            <b-form-input
                                                                v-model="attr.datos.name"
                                                                :placeholder="attr.tags.hasOwnProperty('multivalued') ? 'Ingrese valor 1,valor 2,..,n':'Ingrese un valor'"
                                                            >
                                                            </b-form-input>
                                                        </b-input-group>

                                                    </div>                                                         

                                                    <!-- Caso type number -->
                                                    <div v-else-if="attr.value_type == 'number'">
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <b-form-input
                                                                v-model="attr.datos.name"
                                                                placeholder="Ingrese un valor"
                                                                step=0.01
                                                                type="number"
                                                            > 
                                                            </b-form-input>
                                                        </b-input-group>
                                                    </div> 

                                                    <!-- Caso type number_unit -->
                                                    <div v-else-if="attr.value_type == 'number_unit'">
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <!-- Unidades -->
                                                            <b-form-select 
                                                                v-model="attr.datos.unidad"
                                                            >
                                                                <b-form-select-option
                                                                    v-for="(option, index3) in getAttrOptions(attr.allowed_units)"
                                                                    :key="index3"
                                                                    :value="option.value"
                                                                    :title="option.title || null"  
                                                                >
                                                                    {{ option.text }}
                                                                </b-form-select-option>
                                                            </b-form-select>

                                                            <!-- Valor -->
                                                            <b-form-input
                                                                v-model="attr.datos.unidad_valor"
                                                                placeholder="Ingrese un valor"
                                                                step=0.01
                                                                type="number"
                                                            >
                                                            </b-form-input>
                                                        </b-input-group>
                                                    </div> 

                                                    <!-- Caso type boolean -->
                                                    <div v-else-if="attr.value_type == 'boolean'">
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <b-form-select 
                                                                v-model="attr.datos.id"
                                                                @change="setValuesToAttrOnSelect(attr)"
                                                            >
                                                                <b-form-select-option
                                                                    v-for="(option, index3) in getAttrOptions(attr.values)"
                                                                    :key="index3"
                                                                    :value="option.value"
                                                                    :title="option.title || null"  
                                                                >
                                                                    {{ option.text }}
                                                                </b-form-select-option>
                                                            </b-form-select>
                                                        </b-input-group>
                                                    </div> 

                                                    <!-- Caso type list -->
                                                    <div v-else-if="attr.value_type == 'list'">

                                                        <!-- Si es un multi -->
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            v-if="attr.values && attr.values.length > 0 && attr.tags.hasOwnProperty('multivalued')"
                                                            class="mb-2"
                                                            v-bind:class="{
                                                                'attr-requiered' : attr.datos.required,
                                                                'attr-read-only' : attr.datos.read_only
                                                            }"
                                                        >
                                                            <b-form-select
                                                                v-model="attr.datos.id"
                                                                :options="getAttrOptions(attr.values)"
                                                                @change="setValuesToAttrOnSelectMultiple(attr)"
                                                                multiple
                                                            >
                                                            </b-form-select>
                                                        </b-input-group>
                                                            
                                                        <!-- Si es un simple -->
                                                        <b-input-group 
                                                            :prepend="attr.name"
                                                            v-else
                                                            class="mb-2"
                                                        >
                                                            <b-form-select 
                                                                v-model="attr.datos.id"
                                                                @change="setValuesToAttrOnSelect(attr)"
                                                            >

                                                                <b-form-select-option :value="null" disabled>-- Seleccione una opcion --</b-form-select-option>
                                                                <b-form-select-option
                                                                    v-for="(option, index3) in getAttrOptions(attr.values)"
                                                                    :key="index3"
                                                                    :value="option.value"
                                                                    :title="option.title || null"  
                                                                >
                                                                    {{ option.text }}
                                                                </b-form-select-option>
                                                                <b-form-select-option value="OTRO" >Otro</b-form-select-option>
                                                            </b-form-select>

                                                            <b-form-input
                                                                v-if="attr.datos.id == 'OTRO'"
                                                                v-model="attr.datos.valueOtro"
                                                                placeholder="Ingrese un valor"
                                                            >
                                                            </b-form-input>
                                                        </b-input-group>
                                                            
                                                    </div> 
                                                </div>
                                            </div>
                                            

                                        </b-tab>
                                    </b-tabs>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </template>
                
                
                <b-button 
                    variant="success"
                    class="float-right mt-3"
                    @click="guardarRelevamiento()"
                    v-if="!loading_rel"
                >
                    Guardar
                </b-button>
                <b-button v-else variant="success" class="float-right mt-3">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </b-button>

            </div>
        </b-card>
    </div>  
</template>

<script>

export default {
    data(){
        return {
            //Flags and Ini Set
            loading: true,
            loading_rel: false,
            //Datos Iniciales
            id_producto: null,
            producto: null,
            categorias: [],
            datosRelevamiento: {
                id_producto: null,
                keywords_busqueda: null,
                keywords_categoria: null,
                keywords_negadas: null,
                precio_max: null,
                precio_min: null,
                siteId: null,
            },
            resultadoRelevamiento: {
                categoriasSelect: [],
                itemsSelect: [],
                categoriasDatos: []
            },
            //Constantes generales
            arrBooleanOptions: [
                {value: true, text: 'SI'},
                {value: false, text: 'NO'},
            ],
            //Datos Obligatorios
            datosObligatorios: {
                categoriaFiscal: {
                    arrOpciones: [],
                    selected: null
                } ,
                pesos:{
                    anteriores: [],
                    items: [],
                    optionSelect: null,
                    arrUnidades: [ 
                        { value: "kg", text: "kg"},
                        { value: "g", text: "g"},
                        { value: "mg", text: "mg"},
                        { value: "t", text: "t"},
                        { value: "lb", text: "lb"},
                        { value: "oz", text: "oz"},
                        { value: "mcg", text: "mcg"},
                    ],
                    selected: {
                        value: null,
                        unit: null,
                        type: null,
                    },
                    aprox:false
                },
                medidas:{
                    anteriores: [],
                    items: [],
                    optionSelect: null,
                    arrUnidades: [ 
                        { value: "U", text: "U"},
                        { value: "km", text: "km"},
                        { value: "m", text: "m"},
                        { value: "cm", text: "cm"},
                        { value: "mm", text: "mm"},
                        { value: "µm", text: "µm"},
                        { value: "nm", text: "nm"},
                        { value: "yd", text: "yd"},
                        { value: "ft", text: "ft"},
                        { value: "in", text: "in"},
                        { value: "\"", text: "\""},
                        { value: "pulgadas", text: "pulgadas"},
                        { value: "manos", text: "manos"}
                    ],
                    selected: {
                        x: null,
                        x_unit: "cm",
                        y: null,
                        y_unit: "cm",
                        z: null,
                        z_unit: "cm",
                        type: null,
                    },
                    aprox: false
                },
                codigos:{
                    anteriores: [],
                    items: [],
                    optionSelect: null,
                    selected: {
                        value: null,
                        type: null,
                    }
                },
                flags:{
                    trafo: false,
                    baterias: false,
                    cable: false,
                }
            },
            //Datos Imagenes
            datosImagenes: {
                aux_img_add: '',
                agregadas: [],
                agregadas_original: [],
                anteriores: [],
                items: [],
                selected: [],
                primaria: ''
            },
            //Datos Videos
            datosVideos: {
                aux_video_add: '',
                agregadas: [],
                anteriores: [],
                items: [],
                selected: null,
            },
            //Datos Attr
            datosAttr: { 
                noDeseados : [
                    'PRODUCT_CHEMICAL_FEATURES',
                    'FOODS_AND_DRINKS',
                    'MEDICINES',
                    'ADDITIONAL_INFO_REQUIRED',
                    'EXCLUSIVE_CHANNEL',
                    'PRODUCT_DATA_SOURCE'
                ],
                noShow : [
                    'PACKAGE_LENGTH',
                    'PACKAGE_WEIGHT',
                    'PACKAGE_WIDTH',
                    'PACKAGE_HEIGHT',
                    'LENGTH',
                    'WEIGHT',
                    'WIDTH',
                    'HEIGHT',
                    'IS_SUITABLE_FOR_SHIPMENT',
                    'SHIPMENT_PACKING',
                    'PRODUCT_FEATURES',
                    'IS_FLAMMABLE',
                    'SELLER_SKU',
                    'GTIN',
                ],
                categoriaMeli: [],
                categoriaMeliPrimaria: null,
                anteriores: [],
                items: [],
            },
            datosExtra:{
                precio:{
                    min: null,
                    avg: null,
                    max: null,
                    id_publicacion_min: null,
                    currency_id: null
                }
            },
            restriccionesProveedores: [
                {
                    proveedor: 'Thomann',
                    keyword: 'thomann',
                    cant_max_img: 3,
                    cant_img: 0,
                    no_primaria: false
                }
            ]
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener producto');

                console.log('Producto: ',response.body.data);
                this.producto = response.body.data;
                if(this.producto.ProductoFlag){
                    this.datosObligatorios.flags.trafo = this.producto.ProductoFlag.trafo;
                    this.datosObligatorios.flags.cable = this.producto.ProductoFlag.cable;
                    this.datosObligatorios.flags.baterias = this.producto.ProductoFlag.baterias;
                }

                //Busco si tenia alguna categoria fiscal anterior
                this.producto.ProductoCategoriaFiscals.forEach(cat => {
                    if(cat.principal) this.datosObligatorios.categoriaFiscal.selected = cat.id_categoria;
                });

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getCategoriasFiscales(){
            try {
                let response = await this.$http.get(`negocio/categoria-fiscal`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener categorias fiscales');

                console.log('categorias fiscales: ',response.body.data);

                response.body.data.forEach(element => {
                    this.datosObligatorios.categoriaFiscal.arrOpciones.push({
                        value: element.id,
                        text: element.categoria,
                    });
                    if(element.principal){
                        this.datosObligatorios.categoriaFiscal.selected = element.id;
                    }
                });
                return true;
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getCategoriasProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/categorias`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener categorias');

                console.log('categorias: ',response.body.data);

                this.categorias = response.body.data;
                return true;
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getImagenesProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/img`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener imagenes del producto');
                console.log('Imgs Producto: ',response.body.data);
                this.datosImagenes.anteriores = response.body.data;
                this.datosImagenes.anteriores.forEach(imgAnt => {
                    if(this.datosImagenes.agregadas_original.indexOf(imgAnt.original_url) == -1) this.datosImagenes.agregadas_original.push(imgAnt.original_url);
                    if(imgAnt.selected){
                        let result = this.checkCantidadImgSelectedPorProveedor('ADD',imgAnt.path, imgAnt.original_url);
                        if(!result) this.datosImagenes.selected.push(imgAnt.path);
                        else console.log(`No puede agregar mas imagenes de ese proveedor (${result})`);
                    } 
                    if(imgAnt.primaria){

                        let valida = true;
                        let mensaje = null;
                        this.restriccionesProveedores.forEach(element => {
                            if( element.no_primaria ){
                                if(imgAnt.path.indexOf(element.keyword) != -1 || ( imgAnt.original_url && imgAnt.original_url.indexOf(element.keyword) != -1) ) {
                                    valida = false;
                                    mensaje = `La foto primaria no puede pertenecer al proveedor: ${element.proveedor}`;
                                }
                            }
                        });

                        if(valida) this.datosImagenes.primaria = imgAnt.path;
                        else console.log(mensaje);
                    } 
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getVideosProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/videos`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener videos del producto');
                //Cargo el array de videos con los que tenia
                response.body.data.forEach(element => {
                    this.datosVideos.anteriores.push(element.path);
                    if(element.selected) this.datosVideos.selected=element.path;
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getMedidasAntProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/medidas`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener medidas producto');

                console.log('Medidas Producto: ',response.body.data);

                response.body.data.forEach(element => {
                    this.datosObligatorios.medidas.anteriores.push({
                        x: element.x,
                        x_unit: element.x_unit,
                        y: element.y,
                        y_unit: element.y_unit,
                        z: element.z,
                        z_unit: element.z_unit,
                        type: element.tipo,
                    });

                    if(element.tipo == 'FINAL'){
                        this.datosObligatorios.medidas.selected = {
                            x: element.x,
                            x_unit: element.x_unit,
                            y: element.y,
                            y_unit: element.y_unit,
                            z: element.z,
                            z_unit: element.z_unit,
                            type: element.tipo,
                        };
                        this.datosObligatorios.medidas.optionSelect = this.datosObligatorios.medidas.selected;
                        this.datosObligatorios.medidas.aprox = element.aprox || false;
                    }

                });

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getPesosAntProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/pesos`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener pesos producto');

                console.log('Pesos Producto: ',response.body.data);

                response.body.data.forEach(element => {
                    this.datosObligatorios.pesos.anteriores.push({
                        value: element.peso,
                        unit: element.peso_unit,
                        type: element.tipo,
                    });
                    if(element.tipo == 'FINAL'){
                        
                        this.datosObligatorios.pesos.selected = {
                            value: element.peso,
                            unit: element.peso_unit,
                            type: element.tipo,
                        };
                        this.datosObligatorios.pesos.optionSelect = this.datosObligatorios.pesos.selected;
                        this.datosObligatorios.pesos.aprox = element.aprox || false;
                    }
                });

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getCodigosAntProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/codigos`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener codigos producto');

                console.log('codigos Producto: ',response.body.data);
                
                let first = null;
                response.body.data.forEach((element,index) => {
                    if(index == 0){
                        first = {
                            value: element.codigo,
                            type: element.tipo,
                        };
                    }
                    this.datosObligatorios.codigos.anteriores.push({
                        value: element.codigo,
                        type: element.tipo,
                    });
                    if(element.selected){
                        this.datosObligatorios.codigos.selected = {
                            value: element.codigo,
                            type: element.tipo,
                        };
                        this.datosObligatorios.codigos.optionSelect = this.datosObligatorios.codigos.selected;
                    }
                });

                if(!this.datosObligatorios.codigos.optionSelect && this.datosObligatorios.codigos.anteriores.length == 1){
                    this.datosObligatorios.codigos.selected = first;
                    this.datosObligatorios.codigos.optionSelect = this.datosObligatorios.codigos.selected;
                }

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getAtributosCategorias(){
            this.datosAttr.categoriaMeli =  [];
            let categorias = this.resultadoRelevamiento.categoriasSelect;
            try {
                for (let i = 0; i < categorias.length; i++) {
                    const idCategoria = categorias[i];
                    this.datosAttr.items[idCategoria] = [];
                    if(!this.datosAttr.categoriaMeliPrimaria) this.datosAttr.categoriaMeliPrimaria = idCategoria;

                    const res = await fetch(`https://api.mercadolibre.com/categories/${idCategoria}/attributes`);
                    const data = await res.json();

                    let arrAttrs = [];

                    data.forEach(attr => {
                        if(this.datosAttr.noDeseados.indexOf(attr.id) == -1){
                            attr.datos = {
                                id : null,
                                name: null,
                                unidad: null,
                                unidad_valor: null,
                                valueOtro: null,
                                required: false,
                                read_only: false,
                                fixed: false,
                                show: true,
                            };
                            if(attr.tags.required || attr.tags.catalog_required) attr.datos.required = true;
                            if(this.datosAttr.noShow.indexOf(attr.id) != -1) attr.datos.show = false;
                            if(attr.tags.read_only) attr.datos.read_only = true;
                            if(attr.tags.multivalued){
                                //Si es un String select multivalor 
                                if(attr.values && attr.values.length > 0){
                                    attr.datos.id = [];
                                    attr.datos.name = [];
                                }else{
                                    //Si es un String input multivalor
                                    attr.datos.id = '';
                                    attr.datos.name = '';
                                }
                            }
                            if(attr.tags.fixed && attr.values && attr.values.length > 0){
                                //Viene con valor preseleccionado por la categoria 
                                attr.datos.id = attr.values[0].id;
                                attr.datos.name = attr.values[0].name;
                                attr.datos.fixed = true;
                            }else{
                                attr = this.setDefaultValuesToAttr(attr);
                            }

                            arrAttrs.push(attr);
                        }
                    });

                    this.datosAttr.categoriaMeli.push({
                        idCategoria,
                        attrs: arrAttrs
                    });
                                        
                }
                return true;
            } catch (err) {
                console.log('Error Get Categoria');
                throw err;
            } 
        },
        async getAttrsAntProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/attrs-meli`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener attrs-meli producto');

                response.body.data.forEach(element => {
                    this.datosAttr.anteriores.push(element);
                });

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getDatosItems(){
            let items = this.resultadoRelevamiento.itemsSelect;

            try {
                let arrGrupoItems = [];
                if(items.length > 20){
                    let temparray,chunk = 20;
                    for (let i=0; i<items.length; i+=chunk) {
                        temparray = items.slice(i,i+chunk);
                        arrGrupoItems.push(temparray);
                    }
                }else{
                    arrGrupoItems.push(items);
                }

                let auxPrecioAvg = 0;
                let auxPrecioAvgCount = 0;
                for (let i = 0; i < arrGrupoItems.length; i++) {
                    const grupo = arrGrupoItems[i];

                    if(grupo.length){
                        const res = await fetch(`https://api.mercadolibre.com/items?ids=${grupo.join(',')}`);
                        const data = await res.json();
                        
                        data.forEach(element => {
                            if(element.code == 200 && element.body.status=='active'){
                                //Verifico si viene algun video
                                if(element.body.video_id){
                                    let urlVideo = 'https://www.youtube.com/watch?v='+element.body.video_id;
                                    if(this.datosVideos.items.indexOf(urlVideo) == -1 && this.datosVideos.anteriores.indexOf(urlVideo) == -1) 
                                        this.datosVideos.items.push(urlVideo);
                                } 
                                //Verifico si vienen imagenes
                                if(element.body.pictures || element.body.pictures.length > 0 ){
                                    element.body.pictures.forEach(dataImg => {
                                        if(this.datosImagenes.items.indexOf(dataImg.url) == -1  && this.datosImagenes.anteriores.indexOf(dataImg.url) == -1 && this.datosImagenes.agregadas_original.indexOf(dataImg.url) == -1) 
                                            this.datosImagenes.items.push(dataImg.url);
                                    });
                                }

                                //Verifico los atributos
                                if(element.body.attributes.length > 0 ){
                                    this.checkForPesosInAttrs(element.body.attributes);
                                    this.checkForMedidasInAttrs(element.body.attributes);
                                    this.checkForCodigosInAttrs(element.body.attributes);
                                    element.body.attributes.forEach(attr => {
                                        if(this.datosAttr.items[element.body.category_id][attr.id] == undefined){
                                            this.datosAttr.items[element.body.category_id][attr.id] = attr;
                                        }else{
                                            if(!this.datosAttr.items[element.body.category_id][attr.id].value_id &&  attr.id) {
                                                this.datosAttr.items[element.body.category_id][attr.id] = attr;
                                            }
                                        }
                                    });
                                }
                                if(!this.datosExtra.precio.max || element.body.price > this.datosExtra.precio.max) this.datosExtra.precio.max = element.body.price;
                                if(!this.datosExtra.precio.min || element.body.price < this.datosExtra.precio.min){
                                    this.datosExtra.precio.min = element.body.price;
                                    this.datosExtra.precio.id_publicacion_min = element.body.id;
                                    this.datosExtra.precio.currency_id = element.body.currency_id;
                                } 
                                auxPrecioAvg += element.body.price;
                                auxPrecioAvgCount++;
                            }
                        });

                    }

                }
                if(auxPrecioAvgCount > 0) this.datosExtra.precio.avg = auxPrecioAvg/auxPrecioAvgCount;
                
            } catch (err) {
                console.log('Error Get datos Items');
                throw err;
            }
        },
        


        setValuesAttrByItems(){
            this.resultadoRelevamiento.categoriasSelect.forEach(idCategoria => {
                let datosCategoriaMeli = this.datosAttr.categoriaMeli.find(element => element.idCategoria == idCategoria);
                if(datosCategoriaMeli){
                    datosCategoriaMeli.attrs.forEach(attr => {

                        if(attr.id != 'SELLER_SKU'){
                            let datosItems = this.datosAttr.items[idCategoria][attr.id];   
                            let attrAnt = this.datosAttr.anteriores.find(element => (idCategoria == element.category_id && attr.id == element.id_attr))
                            
                            if(typeof datosItems != 'undefined' && !attr.datos.fixed){
                                if(attr.value_type == 'string' || attr.value_type == 'list'){
                                    if(attr.values && attr.values.length > 0){
                                        if(Object.prototype.hasOwnProperty.call(attr.tags, 'multivalued')){
                                            if(datosItems.value_name){
                                                let arrNames = datosItems.value_name.split(',');
                                                let arrIds = [];
                                                arrNames.forEach(name => {
                                                    let valAux = attr.values.find(attrValue => attrValue.name == name ); 
                                                    if(valAux) arrIds.push(valAux.id);
                                                });
                                                
                                                attr.datos.id = arrIds || attr.datos.id;
                                                attr.datos.name = arrNames || attr.datos.name;    
                                            }
                                        }else{
                                            if(datosItems.value_name){
                                                let valAux = attr.values.find(attrValue => attrValue.name == datosItems.value_name ); 
                                                if(valAux){
                                                    attr.datos.id = valAux.id || attr.datos.id;
                                                    attr.datos.name = datosItems.value_name || attr.datos.name;
                                                }else{
                                                    attr.datos.id = 'OTRO';
                                                    attr.datos.valueOtro = datosItems.value_name
                                                }  
                                            } 
                                        }
                                    }else{
                                        attr.datos.name = datosItems.value_name || attr.datos.name;
                                    }
                                }
                                if(attr.value_type == 'number'){
                                    attr.datos.name = datosItems.value_name || attr.datos.name;
                                }
                                if(attr.value_type == 'number_unit'){
                                    if(datosItems.value_struct){
                                        attr.datos.name = datosItems.value_name || attr.datos.name;
                                        attr.datos.unidad = datosItems.value_struct.unit || attr.datos.unidad;
                                        attr.datos.unidad_valor = datosItems.value_struct.number || attr.datos.unidad_valor;
                                    }
                                }
                                if(attr.value_type == 'boolean'){
                                    attr.datos.id = datosItems.value_id || attr.datos.id;
                                    attr.datos.name = datosItems.value_name || attr.datos.name;
                                }
                            }else if( attrAnt && !attr.datos.fixed){
                                if(attr.value_type == 'string' || attr.value_type == 'list'){
                                    if(attr.values && attr.values.length > 0){
                                        if(Object.prototype.hasOwnProperty.call(attr.tags, 'multivalued')){
                                            if(attrAnt.value_id) attr.datos.id = attrAnt.value_id.split(',');
                                            if(attrAnt.value_name) attr.datos.name = attrAnt.value_name.split(',');
                                        }else{
                                            if(attrAnt.value_id) attr.datos.id = attrAnt.value_id;
                                            if(attrAnt.value_name) attr.datos.name = attrAnt.value_name;
                                            else if(attrAnt.value_id == 'OTRO'){
                                                attr.datos.id = attrAnt.value_id;
                                                attr.datos.valueOtro = attrAnt.value_otro;
                                            }
                                        }
                                    }else{
                                        if(attrAnt.value_name) attr.datos.name = attrAnt.value_name;
                                        else if(attrAnt.value_id == 'OTRO'){
                                            attr.datos.id = attrAnt.value_id;
                                            attr.datos.valueOtro = attrAnt.value_otro;
                                        }
                                    }
                                }
                                if(attr.value_type == 'number'){
                                    if(attrAnt.value_name) attr.datos.name = attrAnt.value_name;
                                }
                                if(attr.value_type == 'number_unit'){
                                    if(attrAnt.value_name) attr.datos.name = attrAnt.value_name;
                                    if(attrAnt.value_type_unit) attr.datos.unidad = attrAnt.value_type_unit;
                                    if(attrAnt.value_unit) attr.datos.unidad_valor = attrAnt.value_unit;
                                }
                                if(attr.value_type == 'boolean'){
                                    if(attrAnt.value_id) attr.datos.id = attrAnt.value_id;
                                    if(attrAnt.value_name) attr.datos.name = attrAnt.value_name;
                                }
                            }
                        }
                    });
                }else{
                    console.log('No se encontraron datos para la categoria: '+idCategoria);
                }
            })  
        },
        setMedidaFinal(valor){
            if(valor == 'OTRO'){
                this.datosObligatorios.medidas.selected = {
                    x: null,
                    x_unit: "cm",
                    y: null,
                    y_unit: "cm",
                    z: null,
                    z_unit: "cm",
                    type: "FINAL",
                }
            }else{
                this.datosObligatorios.medidas.selected = {
                    x: valor.x,
                    x_unit: valor.x_unit,
                    y: valor.y,
                    y_unit: valor.y_unit,
                    z: valor.z,
                    z_unit: valor.z_unit,
                    type: "FINAL",
                }
            }
        },
        setPesoFinal(valor){
            if(valor == 'OTRO'){
                this.datosObligatorios.pesos.selected = {
                    value: null,
                    unit: "kg",
                    type: "FINAL",
                }
            }else{
                this.datosObligatorios.pesos.selected = {
                    value: valor.value,
                    unit: valor.unit,
                    type: "FINAL",
                }
            }
        },
        setCodigoFinal(valor){
            if(valor == 'OTRO'){
                this.datosObligatorios.codigos.selected = {
                    value: null,
                    type: 'GTIN',
                }
            }else{
                this.datosObligatorios.codigos.selected = {
                    value: valor.value,
                    type: valor.type,
                }
            }
        },
        getUrlCheckCodigo(){
            if(this.datosObligatorios.codigos.selected.value){
                return 'https://www.google.com/search?q=upc+'+this.datosObligatorios.codigos.selected.value ;
            }else{
                return false;
            }
        },
        getUrlSearch(type){
            let aux = '';
            let url = 'https://www.google.com/search?q=';
            switch(type){
                case 'codigo' : aux='gtin'; break;
                case 'peso' : aux='shipping+weight'; break;
                case 'medida' : aux='shipping+dimensions'; break;
                case 'img' : aux='&tbm=isch'; break;
                case 'video' : url='https://www.youtube.com/results?search_query='; break;
            }
            let marca = this.producto.marca.replace(/\s/g, '+');
            let modelo = this.producto.modelo.replace(/\s/g, '+');
            url = url+`${marca}+${modelo}`;
            if(aux != '') url = url+`+${aux}`;
            return url;
        },
        checkForPesosInAttrs(attrs){
            attrs.forEach(element => {
                if((element.id == 'WEIGHT' || element.id == 'PACKAGE_WEIGHT') && element.value_struct ){
                    let auxPeso = {
                        value: element.value_struct.number,
                        unit: element.value_struct.unit,
                        type: "PRODUCTO",
                    }
                    if(element.id == 'PACKAGE_WEIGHT') auxPeso.type = 'CAJA';

                    let existe = false;
                    this.datosObligatorios.pesos.items.forEach(element => {
                        if(element.value == auxPeso.value && element.unit == auxPeso.unit && element.type == auxPeso.type ) existe = true;
                    });
                    if(!existe) this.datosObligatorios.pesos.items.push(auxPeso);
                }
            });
        },
        checkForMedidasInAttrs(attrs){
            let auxMedidaProd = {
                type: "PRODUCTO",
                x: null,
                x_unit: null,
                y: null,
                y_unit: null,
                z: null,
                z_unit: null,
            };
            let auxMedidaCaja = {
                type: "CAJA",
                x: null,
                x_unit: null,
                y: null,
                y_unit: null,
                z: null,
                z_unit: null,
            };
            attrs.forEach(element => {
                if(element.id == 'WIDTH' && element.value_struct){
                    auxMedidaProd.x = element.value_struct.number;
                    auxMedidaProd.x_unit = element.value_struct.unit;
                }
                if(element.id == 'PACKAGE_WIDTH' && element.value_struct){
                    auxMedidaCaja.x = element.value_struct.number;
                    auxMedidaCaja.x_unit = element.value_struct.unit;
                }
                if(element.id == 'HEIGHT' && element.value_struct){
                    auxMedidaProd.y = element.value_struct.number;
                    auxMedidaProd.y_unit = element.value_struct.unit;
                }
                if(element.id == 'PACKAGE_HEIGHT' && element.value_struct){
                    auxMedidaCaja.y = element.value_struct.number;
                    auxMedidaCaja.y_unit = element.value_struct.unit;
                }
                if(element.id == 'DEPTH' && element.value_struct){
                    auxMedidaProd.z = element.value_struct.number;
                    auxMedidaProd.z_unit = element.value_struct.unit;
                }
                if(element.id == 'PACKAGE_DEPTH' && element.value_struct){
                    auxMedidaCaja.z = element.value_struct.number;
                    auxMedidaCaja.z_unit = element.value_struct.unit;
                }
            });
        
            let existeProd = false;
            let existeCaja = false;
            this.datosObligatorios.medidas.items.forEach(element => {

                if( element.x == auxMedidaProd.x &&
                    element.x_unit == auxMedidaProd.x_unit &&
                    element.y == auxMedidaProd.y &&
                    element.y_unit == auxMedidaProd.y_unit &&
                    element.z == auxMedidaProd.z &&
                    element.z_unit == auxMedidaProd.z_unit &&
                    element.tipo == auxMedidaProd.tipo
                ) existeProd = true;

                if( element.x == auxMedidaCaja.x &&
                    element.x_unit == auxMedidaCaja.x_unit &&
                    element.y == auxMedidaCaja.y &&
                    element.y_unit == auxMedidaCaja.y_unit &&
                    element.z == auxMedidaCaja.z &&
                    element.z_unit == auxMedidaCaja.z_unit &&
                    element.tipo == auxMedidaCaja.tipo
                ) existeCaja = true;
            });

            if(!existeProd && (auxMedidaProd.x || auxMedidaProd.y || auxMedidaProd.z)) this.datosObligatorios.medidas.items.push(auxMedidaProd);
            if(!existeCaja && (auxMedidaCaja.x || auxMedidaCaja.y || auxMedidaCaja.z)) this.datosObligatorios.medidas.items.push(auxMedidaCaja);
        },
        checkForCodigosInAttrs(attrs){
            attrs.forEach(attr => {
                if(attr.id == 'GTIN'){
                    
                    let arrCod = attr.value_name ? attr.value_name.split(',') : [];
                    arrCod.forEach(codigo => {
                        if(codigo){
                            let auxCodigo = {
                                value: codigo,
                                type: "GTIN"
                            }
                            let existe = this.datosObligatorios.codigos.items.find(auxCod => auxCod.value == codigo);
                            if(!existe && auxCodigo.value) this.datosObligatorios.codigos.items.push(auxCodigo);
                        }
                    });
                    
                }
            });
        },
        setDefaultValuesToAttr(attr){
            //En caso de que esten los siguientes campos ponerles el valor por default

            //Color
                if(attr.id == 'COLOR'){
                    attr.datos.id = '52049';
                    attr.datos.name = 'Negro';
                }

            //Condicion
                if(attr.id == 'ITEM_CONDITION'){
                    attr.datos.id = '2230284';
                    attr.datos.name = 'Nuevo';
                }

            //sku
                if(attr.id == 'SELLER_SKU'){
                    attr.datos.id = this.producto.sku;
                    attr.datos.name = this.producto.sku;
                }

            //Apto envios
                if(attr.id == 'IS_SUITABLE_FOR_SHIPMENT'){
                    attr.datos.id = '242085';
                    attr.datos.name = 'Sí';
                }

            //Tipo de envio
                if(attr.id == 'SHIPMENT_PACKING'){
                    attr.datos.id = [7435892];
                    attr.datos.name = 'Caja';
                }

            //features
                if(attr.id == 'PRODUCT_FEATURES'){
                    attr.datos.id = [7435883];
                    attr.datos.name = 'Frágil';
                }

            //IS_FLAMMABLE
                if(attr.id == 'IS_FLAMMABLE'){
                    attr.datos.id = '242084';
                    attr.datos.name = 'No';
                }

            return attr;
        },
        setVideoSelect(urlVideo){
            if(this.datosVideos.selected == urlVideo) this.datosVideos.selected = null;
            else this.datosVideos.selected = urlVideo;
        },
        toggleSelectedImg(url,original_url = false, event){
            if(event.ctrlKey) {
                this.$swal.fire({
                    imageUrl: url,
                    imageAlt: 'Custom image',
                })
            }else if(event.shiftKey) {         
                let index = this.datosImagenes.selected.indexOf(url);
                if(index == -1){
                    this.showErrorModal(`Primero debe seleccionar la foto`);
                }else{
                    let valida = true;
                    let mensaje = null;
                    this.restriccionesProveedores.forEach(element => {
                        if( element.no_primaria ){
                            if(url.indexOf(element.keyword) != -1 || ( original_url && original_url.indexOf(element.keyword) != -1) ) {
                                valida = false;
                                mensaje = `La foto primaria no puede pertenecer al proveedor: ${element.proveedor}`;
                            }
                        }
                    });

                    if(valida) this.datosImagenes.primaria = url;
                    else this.showErrorModal(mensaje);
                    
                }
            }else{
                let index = this.datosImagenes.selected.indexOf(url);
                if(index && url == this.datosImagenes.primaria){
                    this.showErrorModal(`Primero debe seleccionar otra foto como primaria`);
                }
                else{
                    if( index != -1){
                        this.checkCantidadImgSelectedPorProveedor('DEL',url, original_url);
                        this.datosImagenes.selected.splice(index, 1);
                    }else{
                        //this.datosImagenes.selected.push(url);
                        let result = this.checkCantidadImgSelectedPorProveedor('ADD',url, original_url);
                        if(!result) this.datosImagenes.selected.push(url);
                        else this.showErrorModal(`No puede agregar mas imagenes de ese proveedor (${result})`);
                    }
                }
            }
        },
        checkProveedorImg(url,original_url = false){
            let arrAux = this.restriccionesProveedores;
            for (let i = 0; i < arrAux.length; i++) {
                const element = arrAux[i];
                if(url && url.includes(element.keyword)) return element.proveedor;
                if(original_url && original_url.includes(element.keyword)) return element.proveedor;
            }
            return null;
        },
        checkCantidadImgSelectedPorProveedor(action, url, original_url){
            for (let i = 0; i < this.restriccionesProveedores.length; i++) {
                const element = this.restriccionesProveedores[i];
                if((url && url.includes(element.keyword)) || (original_url && original_url.includes(element.keyword))) {
                    if(action == 'ADD'){
                        if(element.cant_img >= element.cant_max_img) return element.proveedor;
                        element.cant_img++;
                        return false;
                    }else{
                        if(element.cant_img > 0) element.cant_img--;
                        return false;
                    }
                }
            }
        },
        async agregarImgNueva(){
            let url = this.datosImagenes.aux_img_add;

            if(this.datosImagenes.anteriores.indexOf(url) == -1 && this.datosImagenes.items.indexOf(url) == -1 && this.datosImagenes.agregadas.indexOf(url) == -1 && this.datosImagenes.agregadas_original.indexOf(url) == -1){
                let result = await this.checkValidUrlImg(url);
                if(result){
                    this.datosImagenes.agregadas.push(url);
                    this.datosImagenes.aux_img_add = '';
                }else{
                    this.showErrorModal('Ese url no es una imagen valida');
                }
            }else{
                this.showErrorModal('Esa imagen ya esta en la lista');
            }
        },
        checkValidUrlImg(url){
            return new Promise((resolve, reject) => {
                let img = new Image()
                img.onload = () => {
                    if (img.width > 0) {
                        resolve(img.height)
                    }else{
                        reject;
                    }
                }
                img.onerror = reject
                img.src = url
            })
        },
        async agregarVideoNuevo(){
            let url = this.datosVideos.aux_video_add;

            if(this.datosVideos.anteriores.indexOf(url) == -1 && this.datosVideos.items.indexOf(url) == -1 && this.datosVideos.agregadas.indexOf(url) == -1 ){
                let result = await this.checkValidUrlVideo(url);
                if(result){
                    this.datosVideos.agregadas.push(url);
                    this.datosVideos.aux_video_add = '';
                }else{
                    this.showErrorModal('Ese url no es un video valido');
                }
            }else{
                this.showErrorModal('Ese video ya esta en la lista');
            }
        },
        checkValidUrlVideo(url) {
            return new Promise((resolve, reject) => {
                url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                let id_video = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_]/i)[0] : url[0];

                let img = new Image()
                img.src = "http://img.youtube.com/vi/" + id_video + "/mqdefault.jpg";
                
                img.onload = () => {
                    if (img.width !== 120) {
                        resolve(img.src)
                    }else{
                        reject;
                    }
                }
                img.onerror = reject
            })
        },
        getAttrOptions(arrAttr){
            let options = [];
            
            arrAttr.forEach(attr => {
                options.push({
                    value: attr.id,
                    text: attr.name
                });
            });

            return options;
        },
        setValuesToAttrOnSelectMultiple(attr){
            attr.datos.name = [];
            attr.datos.id.forEach(element => {
                const found = attr.values.find(element2 => element == element2.id);
                if(found) attr.datos.name.push(found.name); 
                else attr.datos.name.push('');
            });
        },
        setValuesToAttrOnSelect(attr){
            if(attr.datos.id != 'OTRO'){
                const found = attr.values.find(element => element.id == attr.datos.id);
                if(found) attr.datos.name = found.name;
            }else{
                attr.datos.name = '';
            }
        },
        changeCategoriaPrimaria(value){
            if(this.datosAttr.categoriaMeliPrimaria == value) this.datosAttr.categoriaMeliPrimaria = null;
            else this.datosAttr.categoriaMeliPrimaria = value;
        },
        setDefaultValuesObligatorios(){
            let medidasDefault = true;
            if(this.datosObligatorios.medidas.optionSelect) medidasDefault = false;
            if(medidasDefault && this.datosObligatorios.medidas.anteriores.length > 0) medidasDefault = false;
            if(medidasDefault && this.datosObligatorios.medidas.anteriores.items > 0) medidasDefault = false;
            if(medidasDefault) {
                this.datosObligatorios.medidas.optionSelect = 'OTRO';
                this.setMedidaFinal('OTRO');
            }
            

            let pesoDefault = true;
            if(this.datosObligatorios.pesos.optionSelect) pesoDefault = false;
            if(pesoDefault && this.datosObligatorios.pesos.anteriores.length > 0) pesoDefault = false;
            if(pesoDefault && this.datosObligatorios.pesos.anteriores.items > 0) pesoDefault = false;
            if(pesoDefault){
                this.datosObligatorios.pesos.optionSelect = 'OTRO';
                this.setPesoFinal('OTRO');
            } 

            let codigoDefault = true;
            if(this.datosObligatorios.codigos.optionSelect) codigoDefault = false;
            if(codigoDefault && this.datosObligatorios.codigos.anteriores.length > 0) codigoDefault = false;
            if(codigoDefault && this.datosObligatorios.codigos.anteriores.items > 0) codigoDefault = false;
            if(codigoDefault){
                this.datosObligatorios.codigos.optionSelect = 'OTRO';
                this.setCodigoFinal('OTRO');
            } 

        },
        changeAllUnits(type,unit){
            if(type == 'medidas'){
                this.datosObligatorios.medidas.selected.x_unit = unit;
                this.datosObligatorios.medidas.selected.y_unit = unit;
                this.datosObligatorios.medidas.selected.z_unit = unit;
            }
            if(type == 'peso'){
                this.datosObligatorios.pesos.selected.unit = unit;
            }
        },
        
        
        async guardarRelevamiento(){
            this.loading_rel = true;
            try {
                //Medidas
                let medidas = {...this.datosObligatorios.medidas.selected};
                let rtaMedidas = this.validarMedidas(medidas);
                if(!rtaMedidas.valid) throw new Error(rtaMedidas.msg);
                medidas.tipo = medidas.type;
                medidas.aprox = this.datosObligatorios.medidas.aprox;
                //Peso
                let peso = {...this.datosObligatorios.pesos.selected};
                let rtaPeso = this.validarPeso(peso);
                if(!rtaPeso.valid) throw new Error(rtaPeso.msg);
                peso.tipo = peso.type;
                peso.peso = peso.value;
                peso.peso_unit = peso.unit;
                peso.aprox = this.datosObligatorios.pesos.aprox;
                //Codigo
                let codigo = {...this.datosObligatorios.codigos.selected};
                let rtaCodigo = this.validarCodigo(codigo);
                if(!rtaCodigo.valid) throw new Error(rtaCodigo.msg);
                codigo.codigo = codigo.value;
                codigo.tipo = codigo.type;
                //Flags
                let flags = this.datosObligatorios.flags;
                let rtaFlags = this.validarFlags(flags);
                if(!rtaFlags.valid) throw new Error(rtaFlags.msg);
                //categoria fiscal
                let cat_fiscal = this.datosObligatorios.categoriaFiscal.selected;
                if(!cat_fiscal) throw new Error('Debe seleccionar una categoria fiscal');
                //Imagenes
                let imagenes = this.datosImagenes.selected;
                if(imagenes.length < 1) throw new Error('Debe seleccionar al menos 1 imagen');
                //Videos
                let video = this.datosVideos.selected;
                //Attr
                let rtaAttrs = this.validarAttrs();
                if(!rtaAttrs.valid) throw new Error(rtaAttrs.msg);
                let attrs = rtaAttrs.data;

                //ML SiteID
                let siteId = this.datosRelevamiento.siteId;

                //Precio
                let precios = this.datosExtra.precio;

                //Categorias
                let categorias = this.resultadoRelevamiento.categoriasDatos;
                if(!this.datosAttr.categoriaMeliPrimaria) throw new Error('Debe seleccionar una categoria como primaria');
                categorias.forEach(element => {
                    if(element.id_categoria == this.datosAttr.categoriaMeliPrimaria) element.primaria = true;
                    else element.primaria = false;
                });

                let datosRelevamientoCompleto = {
                    siteId,
                    medidas,
                    peso,
                    codigo,
                    flags,
                    imagenes,
                    img_primaria: this.datosImagenes.primaria,
                    video,
                    attrs,
                    precios,
                    categorias,
                    cat_fiscal
                };

                console.log(datosRelevamientoCompleto);

                let response = await this.$http.post(`producto/${this.id_producto}/relevar/${siteId}`,datosRelevamientoCompleto, {headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (response.body && response.body.success) {   
                    this.$store.commit('setMensajePage',{
                        route : 'listProductos',
                        data: {
                            type: 'success',
                            msg: 'Producto relevado correctamente'
                        }
                    });
                    let mensajes = this.$store.getters.getMensajesPag;
                    console.log(mensajes);
                    this.$router.go(-2)
                } else {
                    console.log(response);
                }


                
                this.loading_rel = false;

            } catch (err) {
                console.log(err);
                let error = 'Al conectar con el servidor';
                if(err.message) error = err.message;
                this.showErrorModal(error);
                this.loading_rel = false;
            }

            
            
        },
        validarMedidas(medidas){
            let valido = {
                valid: true,
                msg: '',
            };
            if(medidas.type != 'FINAL'){
                valido.valid = false;
                valido.msg = 'Debe ingresar alguna medida';
            }
            else if(!medidas.x){
                valido.valid = false;
                valido.msg = 'Debe ingresar el ancho';
            }
            else if(!medidas.y){
                valido.valid = false;
                valido.msg = 'Debe ingresar el alto';
            }
            else if(!medidas.z){
                valido.valid = false;
                valido.msg = 'Debe ingresar la profundidad';
            }

            return valido;
        },
        validarPeso(peso){
            let valido = {
                valid: true,
                msg: '',
            };
            if(peso.type != 'FINAL'){
                valido.valid = false;
                valido.msg = 'Debe ingresar algun peso';
            }
            else if(!peso.value){
                valido.valid = false;
                valido.msg = 'Debe ingresar el peso';
            }

            return valido;
        },
        validarCodigo(codigo){
            let valido = {
                valid: true,
                msg: '',
            };
            if(!codigo.value){
                valido.valid = false;
                valido.msg = 'Debe ingresar el codigo';
            }
            if(codigo.value.length < 8 ){
                valido.valid = false;
                valido.msg = 'El codigo debe tener al menos 8 numeros';
            }
            if(codigo.value.length > 14 ){
                valido.valid = false;
                valido.msg = 'El codigo debe tener al maximo 14 numeros';
            }

            return valido;
        },
        validarFlags(flags){
            let valido = {
                valid: true,
                msg: '',
            };
            if(flags.trafo == null){
                valido.valid = false;
                valido.msg = 'Debe ingresar si viene con trafo';
            }
            else if(flags.baterias == null){
                valido.valid = false;
                valido.msg = 'Debe ingresar si viene con baterias';
            }
            else if(flags.cable == null){
                valido.valid = false;
                valido.msg = 'Debe ingresar si viene con cable 110/220';
            }

            return valido;
        },
        validarAttrs(){

            let valido = {
                valid: true,
                msg: '',
                data: null
            };

            let arrAttByCategoria = [];
            this.datosAttr.categoriaMeli.forEach(categoria => {
                let auxAttrs = [];
                categoria.attrs.forEach(attr => {
                    let datosAttr = {
                        id: attr.id,
                        name: attr.name,
                        value_type: attr.value_type,
                        value: {
                            id: attr.datos.id,
                            name: attr.datos.name,
                            unidad: attr.datos.unidad,
                            unidad_valor: attr.datos.unidad_valor,
                            valueOtro: attr.datos.valueOtro,
                        },
                        required: attr.datos.required
                    };

                    if(datosAttr.required && (datosAttr.value_type == 'string' || datosAttr.value_type == 'number' || datosAttr.value_type == 'boolean' || datosAttr.value_type == 'list')){
                        if(!datosAttr.value.name || datosAttr.value.name == ''){
                            if(!datosAttr.value.valueOtro || datosAttr.value.valueOtro == ''){
                                valido.valid = false;
                                valido.msg = `Debe ingresar el attr ${datosAttr.id} en la categoria ${categoria.idCategoria}`;
                                return valido;
                            }
                        }
                    }
                    if(datosAttr.required && datosAttr.value_type == 'number_unit'){
                        if(!datosAttr.value.unidad || datosAttr.value.unidad == '' || !datosAttr.value.unidad_valor || datosAttr.value.unidad_valor == ''){
                            valido.valid = false;
                            valido.msg = `Debe ingresar el attr ${datosAttr.id} en la categoria ${categoria.idCategoria}`;
                            return valido;
                        }
                    }
                    auxAttrs.push(datosAttr);
                });
                arrAttByCategoria.push({
                    idCategoria : categoria.idCategoria,
                    attrs: auxAttrs
                });
            });
            
            valido.data = arrAttByCategoria;
            return valido;
        },

        checkMarcarPendiente(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de marcar el producto como pendiente?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.marcarProductoComoPendiente();
                }
            })
        },

        async marcarProductoComoPendiente(){
            let response = await this.$http.put(`producto/${this.id_producto}/pendiente`,{pendiente: true}, {headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
            if (response.body && response.body.success) {   
                this.$store.commit('setMensajePage',{
                    route : 'listProductos',
                    data: {
                        type: 'success',
                        msg: 'Producto marcado como pendiente'
                    }
                });
                let mensajes = this.$store.getters.getMensajesPag;
                console.log(mensajes);
                this.$router.go(-2)
            } else {
                console.log(response);
            }
        }



    },
    async beforeMount(){
        //Traigo id Producto
        this.id_producto = this.$route.params.id || null;
        this.loading = true;

        //Traigo los datos del relevamiento
        this.datosRelevamiento=this.$store.getters.getDatosRelevamiento;
        this.resultadoRelevamiento=this.$store.getters.getResuladoRelevamiento;

        //Traigo los datos del producto
        await this.getProducto();
        await this.getCategoriasProducto();
        await this.getCategoriasFiscales();
        await this.getImagenesProducto();
        await this.getVideosProducto();
        await this.getMedidasAntProducto();
        await this.getPesosAntProducto();
        await this.getCodigosAntProducto();
        await this.getAttrsAntProducto();
        
        //Traigo los attr de las categorias
        await this.getAtributosCategorias();

        //Busco los datos de los items para relevar
        await this.getDatosItems();

        //Seteo los valores traidos de los items
        this.setValuesAttrByItems();

        //Seteo valores obligaotios default
        this.setDefaultValuesObligatorios();
        
        this.loading = false;
    }
}
</script>

<style scoped>
    .accordion > .card > .card-header{
        background-color: transparent;
        border-bottom:none;
    }
    .accordion > .card {
        border-radius: 0.25rem !important;
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
    }
    .btn-info {
        box-shadow: none !important;
    }
    .container{
        max-width: 80% !important;
    }
    .selected-img{
        border-color: #5e5cef !important;
        border: 2px solid;
    }
    .primary-img{
        border-color: #ef5c68 !important;
    }
    .attr-requiered * {
        border-color: #cc4154 !important;
    }
    .attr-read-only * {
        border-color: #5e5cef !important;
    }
    .btn-unit{
        padding-left: 10px;
        padding-right: 10px;
        background-color: #7a7f83a6 !important;
    }
</style>