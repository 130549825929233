<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">
                    Thomann Items 
                    <span v-if="!loading">
                        [ {{pagination.count}} ]
                    </span>
                </div>

                    <b-button class="float-right mr-3" @click="displayFiltros = !displayFiltros" size="sm" variant="secondary">
                        Filtros
                    </b-button>
                    <div class="clearfix"></div>
                    <b-collapse id="collapse-4" v-model="displayFiltros" class="mt-4">
                        <div class="row">
                            <!-- KeyWord -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Keyword">
                                    <b-form-input
                                        type="text" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control" 
                                        :disabled = "loading"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Marca -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Marca">
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.marca"
                                        :options="marcas"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todas</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- Sku -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="SKU">
                                    <b-form-input
                                        type="text" 
                                        v-model.trim="filtros.sku"
                                        class="form-control" 
                                        :disabled = "loading"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <!-- Disponible -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Disponible">
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.disponible"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="TODO">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>

                            <!-- Done -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Producto">
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.done"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="TODO">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>

                            <!-- Relevado -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Relevado">
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.relevado"
                                        :options="[{value: false, text: 'Pendiente'},{value: true, text: 'Listo'}]"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="TODO">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>

                            <!-- Error -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Error">
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.error"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="TODO">Todos</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>


                            <div class="col-12">
                                <b-button class="float-right mb-2" size="sm" @click="aplicarFiltros()" variant="primary">
                                    Aplicar
                                </b-button>
                            </div>
                        </div>
                        
                    </b-collapse>

            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else>
                <div class="mb-3">
                    <div class="row item-row mb-2" v-for="(item, index) in arrItemsFiltrados" :key="index">
                        <div class="col-12 col-md-2 mb-sm-2">
                            <a :href="item.origin_url" target="_blank" rel="noopener noreferrer">
                                <img 
                                    :src="item.origin_img" 
                                    class="img-thumbnail" 
                                    :alt="item.titulo" 
                                    style="max-width: 80px;"
                                >
                            </a>
                        </div> 
                        <div class="row col-12 col-md-9 mb-sm-2">
                            <div class="col-12 col-md-5 datos-item">
                                <span class="mb-sm-2"><b>Marca: </b>{{ item.marca }}</span><br>
                                <span class="mb-sm-2"><b>Modelo: </b>{{ item.modelo }}</span><br>
                                <span class="mb-sm-2"><b>SKU: </b>{{ item.sku }}</span><br>
                                <span class="mb-sm-2"><b>Disp. Stock: </b>{{ item.disp_stock }}</span><br>
                                <span class="mb-sm-2"><b>Precio: </b>{{ item.price_thomann }} {{item.price_currency}};  ({{ item.price_by }})</span><br>
                                
                            </div>
                            <div class="col-12 col-md-4 datos-item">
                                <span class="mb-sm-2"><b>Fotos: </b>{{ item.imagenes ? item.imagenes.length : '-' }}</span><br>
                                <span class="mb-sm-2"><b>Videos: </b>{{ item.videos ? item.videos.length : '-' }}</span><br>
                                <span class="mb-sm-2"><b>Manual: </b>{{ item.manual ? 'Si' : 'No' }}</span><br>
                            </div>
                            <div class="col-11 col-md-3">
                                <b-badge class="mr-2" :variant="item.updated ? 'success' : 'danger'">Updated</b-badge>
                                <b-badge class="mr-2" :variant="item.done ? 'success' : 'danger'">Done</b-badge>
                                <b-badge class="mr-2" :variant="item.error ? 'success' : 'danger'">Error</b-badge>
                                <b-badge class="mr-2" :variant="item.relevado ? 'success' : 'danger'">Relevado</b-badge>
                                <b-badge class="mr-2" :variant="item.disponible ? 'success' : 'danger'">Disponible</b-badge>
                            </div>
                        </div>
                    </div>
                </div>

                <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.count"
                    :per-page="pagination.limit"
                    align="center"
                    first-number
                    last-number
                    @change="handlePageChange"
                ></b-pagination>

            </div>
        </b-card>
    </div>  
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            pagination:{
                page: 1,
                limit: 10,
                count: 0,
            },
            items: [],
            displayFiltros : false,
            marcas: [],
            booleanOpts: [{value: false, text: "No"},{value: true, text: "Si"}],
            filtros: {
                keyword: '',
                marca: '',
                sku: '',
                disponible: 'TODO',
                done: 'TODO',
                relevado: 'TODO',
                error: 'TODO'
            }
        }
    },
    computed: {
        arrItemsFiltrados() {
            console.log(this.filtros);
            return this.items.filter(element => {
                let passMarca = true;
                let passDispo = true;
                let passDone = true;
                let passRelevado = true;
                let passError = true;
                let passSku = true;
                let passKey = true;
                let passSelected = true;
                if(this.filtros.marca != "" && element.marca != this.filtros.marca) passMarca = false;
                if(this.filtros.done != "TODO" && element.done != this.filtros.done) passDone = false;
                if(this.filtros.disponible != "TODO" && element.disponible != this.filtros.disponible) passDispo = false;
                if(this.filtros.relevado != "TODO" && element.relevado != this.filtros.relevado) passRelevado = false;
                if(this.filtros.error != "TODO" && element.error != this.filtros.error) passError = false;
                if(this.filtros.keyword != ""){
                    passKey = false;
                    let key = this.filtros.keyword.toLowerCase();
                    let modelo = element.modelo.toLowerCase();
                    if(modelo.includes(key)) passKey = true;
                }
                if(this.filtros.sku != ""){
                    passSku = false;
                    let sku = this.filtros.sku.toLowerCase();
                    let skuElement = element.sku.toLowerCase();
                    if(skuElement.includes(sku)) passSku = true;
                }
                if(passMarca && passSku && passKey && passDispo && passRelevado && passError && passDone && passSelected) return element;
            });
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getListaItems(){
            try {
                let query = `scraper/thomann/item`+this.armarQuery(false);

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener items');

                console.log('Items: ',response.body.data);
                this.items = response.body.data.rows;

                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getListaMarcas(){
            try {
                let response = await this.$http.get(`scraper/thomann/item/marca`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de marcas');
                this.marcas = [];
                console.log('Marcas: ',response.body.data);
                response.body.data.forEach(element => {
                    if(this.marcas.indexOf(element.marca) == -1) this.marcas.push(element.marca);
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async handlePageChange(value){
            this.loading = true;
            this.pagination.page = value;
            this.armarQuery();
            await this.getListaItems();
            this.loading = false;
        },
        async aplicarFiltros (){

            this.loading = true;
            await this.getListaItems();
            this.loading = false;
        },
        armarQuery(){
            let queryParams = {};
            queryParams.page = this.pagination.page;
            queryParams.limit = this.pagination.limit;
            if(this.filtros.keyword != '')    queryParams.keyword = this.filtros.keyword;
            if(this.filtros.marca != '')    queryParams.marca = this.filtros.marca;
            if(this.filtros.disponible != 'TODO')    queryParams.disponible = this.filtros.disponible;
            if(this.filtros.done != 'TODO')    queryParams.done = this.filtros.done;
            if(this.filtros.relevado != 'TODO')    queryParams.relevado = this.filtros.relevado;
            if(this.filtros.error != 'TODO')    queryParams.error = this.filtros.error;
            

            let query = '';
            for( var key in queryParams ) {
                var value = queryParams[key];
                if(query == '') query+= '?'+key+'='+value;
                else query+= '&'+key+'='+value;
            }
            this.$router.push({ query: Object.assign({}, {...this.$route.query}, queryParams) }).catch(() => {});
            return query;
        }
    },
    async beforeMount(){
        this.pagination.page = this.$route.query.page || 1
        this.pagination.limit = this.$route.query.limit || 10
        this.filtros.keyword = this.$route.query.keyword || ''
        this.filtros.marca = this.$route.query.marca || ''
        this.filtros.sku = this.$route.query.sku || ''
        this.filtros.disponible = this.$route.query.disponible || 'TODO'
        this.filtros.done = this.$route.query.done || 'TODO'
        this.filtros.relevado = this.$route.query.relevado || 'TODO'
        this.filtros.error = this.$route.query.error || 'TODO'

        this.loading = true;
        await this.getListaItems();
        await this.getListaMarcas();
        this.loading = false;
    }
}
</script>

<style scoped>
    .item-row{
        border: 1px solid rgb(0 0 0 / 25%);
        padding: 10px 15px;
        border-radius: 5px;
    }

    .datos-item{
        font-size: small;
    }

</style>