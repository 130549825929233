<template>
  <div>
    <b-card header-tag="header" >
        <template #header>
            <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
            </b-button>
            <div class="float-left table-panel-title">Relevar Producto: {{producto ? producto.sku : 'Loading ...'}}</div>

            <!-- Boton Links a proveedores-->
            <b-dropdown 
                text="Datos Prov." 
                class="float-right ml-1" 
                size="sm" 
                variant="primary"
                v-if="producto && producto.Articulos && producto.Articulos.length"
            >
                <b-dropdown-item 
                    v-for="(articulo, index) in producto.Articulos"
                    :key="index"
                    :href="articulo.link_item_proveedor"
                    target="_blank"
                >
                    Articulo {{index}}
                </b-dropdown-item>
            </b-dropdown>
        </template>
        
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <!-- Datos del producto -->
            <b-card header-tag="header" class="mb-3">
                <template #header>
                    <h6 class="mb-0">Datos del Producto</h6>
                </template>
                
                <div class="row">
                    <div class="col-12 col-md-4 mb-sm-2">
                        <div class="prod-img"> 
                            <img :src="producto.mini_img" class="img-thumbnail" alt="imagen Producto" style="max-width: 120px;">
                        </div>
                    </div>  
                    <div class="row col-12 col-md-8 mb-sm-2">
                        <!-- Marca -->
                        <div class="col-12 mb-1">
                            <b-input-group size="sm" prepend="Marca">
                                <b-form-input v-model.trim="producto.marca" disabled></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Modelo -->
                        <div class="col-12 mb-3">
                            <b-input-group size="sm" prepend="Modelo">
                                <b-form-input v-model.trim="producto.modelo" disabled></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Keywords Busqueda -->
                        <div class="col-12 mb-1">
                            <b-input-group size="sm" prepend="Keywords Busqueda">
                                <b-form-input v-model.trim="datosRelevamiento.keywords_busqueda" :disabled="loading_busqueda"></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Keywords Negadas -->
                        <div class="col-12 mb-1">
                            <b-input-group size="sm" prepend="Keywords Negadas">
                                <b-form-input v-model.trim="datosRelevamiento.keywords_negadas" :disabled="loading_busqueda"></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Keywords Categoria -->
                        <div class="col-12 mb-3">
                            <b-input-group size="sm" prepend="Keywords Categoria">
                                <b-form-input v-model.trim="datosRelevamiento.keywords_categoria" :disabled="loading_busqueda"></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Pais -->
                        <div class="col-12 col-md-4 mb-2">
                            <b-input-group size="sm" prepend="Pais">
                                <b-form-select 
                                    v-model="datosRelevamiento.siteId" 
                                    :options="arrSiteId"
                                    :disabled="loading_busqueda"
                                    @change="getKeywordsProducto()"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Precio Minimo -->
                        <div class="col-12 col-md-4 mb-2">
                            <b-input-group size="sm" prepend="Precio Minimo">
                                <b-form-input v-model.trim="datosRelevamiento.precio_min" type="number" step="0.01" :disabled="loading_busqueda"></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Precio Maximo -->
                        <div class="col-12 col-md-4 mb-5">
                            <b-input-group size="sm" prepend="Precio Maximo">
                                <b-form-input v-model.trim="datosRelevamiento.precio_max" type="number" step="0.01" :disabled="loading_busqueda"></b-form-input>
                            </b-input-group>
                        </div>

                    </div>        
                </div>

                <b-button class="float-right ml-3" variant="success" size="sm" v-if="relevado && !loading_busqueda" @click="editProductoRelevado()">
                    Editar
                </b-button>
                
                <b-button class="float-right" variant="primary" size="sm" @click="buscarItems()" :disabled="loading_busqueda"  v-if="!loading_busqueda">
                    Buscar
                </b-button>
                <b-button class="float-right mr-3" variant="primary" size="sm" v-else>
                    <b-spinner small></b-spinner> Loading...
                </b-button>

                <b-button class="float-right mr-3" variant="warning" size="sm" @click="limpiarCampos()" :disabled="loading_busqueda">
                    Limpiar
                </b-button>
                
            </b-card>

            <!-- Resultados Relevamiento -->
            <b-card header-tag="header" v-if="!loading_items" class="mt-3">
                <template #header>
                    <h6 class="mb-0">Resultados Relevamiento</h6>
                </template>

                <b-input-group size="sm" prepend="Categorias">
                    <b-form-select 
                        v-model="resuladoRelevamiento.categoriasSelect" 
                        :options="armarSelectCateorias"
                        multiple 
                    >
                    </b-form-select>
                </b-input-group>

                
                <b-card header-tag="header" class="mt-3 mb-4" >
                    <template #header>
                        <div class="float-left">
                            <h6 class="mb-0">Items ( {{resuladoRelevamiento.itemsSelect.length}} / {{itemsFiltrados.length}})</h6>
                        </div>


                        <b-button class="float-right ml-2" @click="toggleSelectAllItems" variant="secondary" size="sm">
                            Todo
                        </b-button>

                        <div class="btn-group float-right" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-sm" 
                                v-bind:class="{'btn-primary':!filtrosRelevamiento.flagFiltroPrecio,'btn-danger':filtrosRelevamiento.flagFiltroPrecio}"
                                @click="toggleFiltroPrecio"
                            >
                                Filtro Precio
                            </button>
                            <button type="button" class="btn btn-sm" 
                                v-bind:class="{'btn-primary':!filtrosRelevamiento.flagFiltroKeywords,'btn-danger':filtrosRelevamiento.flagFiltroKeywords}"
                                @click="toggleFiltroKeywords"
                            >
                                Filtro keywords
                            </button>
                            <button type="button" class="btn btn-sm" 
                                v-bind:class="{'btn-primary':!filtrosRelevamiento.flagFiltroKeywordsNeg,'btn-danger':filtrosRelevamiento.flagFiltroKeywordsNeg}"
                                @click="toggleFiltroKeywordsNeg"
                            >
                                Filtro keywords Neg
                            </button>
                        </div>

                    </template>

                    <b-list-group>
                        <b-list-group-item
                            v-for="(item,index) in itemsFiltrados" :key="index"
                            v-bind:class="{'item-selected': resuladoRelevamiento.itemsSelect.indexOf(item.id) != -1 }"
                            @click="toggleSelectedItem(item.id)"
                        >
                            <div class="row">
                                <div class="col-2 col-md-2" style="cursor:pointer" >
                                    <img :src="item.thumbnail" class="img-thumbnail" alt="imagen Producto" style="max-width: 100px;" :id="'img-item-'+item.id">
                                    <b-tooltip :target="'img-item-'+item.id" triggers="hover" placement="right" style="max-width: 100%;">
                                        <img :src="item.thumbnail" class="img-thumbnail" alt="imagen Producto tooltip" style="width: 300px;">
                                    </b-tooltip>
                                </div>
                                <div class="row col-10 col-md-10">
                                    <div class="col-12">
                                        <a :href="item.permalink" target="_blank">
                                            <b>[{{item.id}}] {{item.title}}</b>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <span class="small"><b>Categoría:</b> {{item.category_id}}</span><br>
                                        <span class="small"><b>Precio:</b> ({{ item.currency_id || '$'}})  {{item.price || 'N/A'}}</span><br>
                                        <span class="small"><b>Atributos:</b> {{ item.attributes.length || '0'}}</span><br>
                                        
                                        
                                    </div>
                                    <div class="col-6">
                                        <span class="small"><b>Vendedor:</b> {{separarNickVendedor(item.seller.permalink)}}</span><br>
                                        <span class="small" v-html="reputacionVendedor(item.seller.seller_reputation)"></span><br>
                                        <span class="small" ><b>Stock:</b> {{item.available_quantity}}</span> - <span class="small"><b>Vendidos:</b> {{item.sold_quantity}}</span><br>
                                    </div>
                                </div>
                                
                            </div>
                        </b-list-group-item>
                    </b-list-group>

                </b-card>

                <b-button variant="success" class="float-right" @click="relevarAtributos()">Relevar</b-button>

            </b-card>
        </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            //Flags and Ini Set
            loading: true,
            loading_busqueda: false,
            loading_items: true,
            arrSiteId : this.$store.getters.getMeliSites,
            
            //Datos Iniciales
            id_producto: null,
            producto: null,
            cuentas_propias: null,
            categoriasOrig: null,
            datosRelevamientoOrig: null,
            relevado: false,

            //Datos Busqueda para Relevar
            datosRelevamiento : {
                id_producto: null,
                keywords_busqueda: '',
                keywords_negadas: '',
                keywords_categoria: '',
                siteId: 'MLA',
                precio_min: '',
                precio_max: '',
            },
            
            //Datos Items Relevados
            items_relevados: [],
            categorias_items_relevados: [],
            filtrosRelevamiento: {
                flagFiltroPrecio: false,
                flagFiltroKeywords: false,
                flagFiltroKeywordsNeg: false,
                flagSelectAllItems: false
            },
            resuladoRelevamiento:{
                itemsSelect: [],
                categoriasSelect: [],
                categoriasDatos: [],
            },
        }
    },
    computed:{
        armarSelectCateorias(){
            let options = [];
            let catAux = [];

            this.categorias_items_relevados.forEach(categoria => {
                options.push({
                    value: categoria.id,
                    text: `[${categoria.id}] `+this.strCategoriaFromRoot(categoria.path_from_root)+` (Items: ${categoria.cant_items})`,
                });
                catAux.push(categoria.id);
            });

            return options;
        }, 
        itemsFiltrados(){

            let keywords = this.datosRelevamiento.keywords_busqueda.toLowerCase().split(' ');
            let keywordsNeg = this.datosRelevamiento.keywords_negadas.toLowerCase().split(' ');

            return this.items_relevados.filter(item => {
                let valido = true;

                let titulo = item.title.toLowerCase();

                //Filtro por keyWords negadas
                if(this.filtrosRelevamiento.flagFiltroKeywordsNeg){
                    keywordsNeg.forEach(word => {
                        if(word != '' && titulo.indexOf(word) != -1)  valido = false;
                    });
                }

                //Filtro por keyWords
                if(this.filtrosRelevamiento.flagFiltroKeywords){
                    keywords.forEach(word => {
                        if(word != '' && titulo.indexOf(word) == -1)  valido = false;
                    });
                }

                //Filtro por categoria
                if(this.resuladoRelevamiento.categoriasSelect.length > 0){
                    if(this.resuladoRelevamiento.categoriasSelect.indexOf(item.category_id) == -1) valido = false;
                }

                //Filtro por precio
                if(this.filtrosRelevamiento.flagFiltroPrecio){
                    if(this.datosRelevamiento.precio_min != '' && item.price < parseFloat(this.datosRelevamiento.precio_min)) valido = false;
                    if(this.datosRelevamiento.precio_max != '' && item.price > parseFloat(this.datosRelevamiento.precio_max)) valido = false;
                }
                
                if(valido) return item;
            });
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener producto');

                console.log('Producto: ',response.body.data);
                this.producto = response.body.data;

                if(this.producto.ProductoFlag.relevado) this.relevado = true;

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getIdsCuentasMeli(){
            try {
                let response = await this.$http.get(`meli/cuenta/propias`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener ids cuentas');

                console.log('cuentas_propias: ',response.body.data);
                this.cuentas_propias = response.body.data;

                return true;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
        },
        async getCategoriasProducto(){
            try {
                let response = await this.$http.get(`producto/${this.id_producto}/categorias`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener categorias producto');
                this.categoriasOrig = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getKeywordsProducto(){
            try {
                this.datosRelevamiento.id_producto = this.id_producto;

                let response = await this.$http.get(`producto/${this.id_producto}/relevar/keywords/${this.datosRelevamiento.siteId}`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener keywords producto');

                console.log('datosRelevamiento: ',response.body.data);
                this.datosRelevamientoOrig = response.body.data || null;
                if(this.datosRelevamientoOrig){
                    this.datosRelevamiento.keywords_busqueda = this.datosRelevamientoOrig.keywords_busqueda || '';
                    this.datosRelevamiento.keywords_negadas =  this.datosRelevamientoOrig.keywords_negadas || '';
                    this.datosRelevamiento.keywords_categoria =  this.datosRelevamientoOrig.keywords_categoria || '';
                    this.datosRelevamiento.siteId =  this.datosRelevamientoOrig.keywords_busqueda || 'MLA';
                    this.datosRelevamiento.precio_min =  this.datosRelevamientoOrig.precio_min || null;
                    this.datosRelevamiento.precio_max =  this.datosRelevamientoOrig.precio_max || null;
                }else{
                    let datosRelevamientoStore = this.$store.getters.getDatosRelevamiento;
                    if(datosRelevamientoStore && datosRelevamientoStore.id_producto == this.id_producto){
                        this.datosRelevamiento = datosRelevamientoStore;
                    } 
                }

                if(this.datosRelevamiento.keywords_busqueda == '') this.datosRelevamiento.keywords_busqueda = this.producto.marca+" "+this.producto.modelo;
                this.datosRelevamiento.siteId = 'MLA';
                if(this.datosRelevamiento.keywords_categoria == '') {
                    if(this.categoriasOrig && this.categoriasOrig.length > 0){
                        let catAux = [];
                        for (let i = 0; i < this.categoriasOrig.length; i++) {
                            const categoria = this.categoriasOrig[i];
                            if(catAux.indexOf(categoria.nombre) == -1){
                                this.datosRelevamiento.keywords_categoria += categoria.nombre;
                                if(i!=this.categoriasOrig.length - 1) this.datosRelevamiento.keywords_categoria += ', ';
                                catAux.push(categoria.nombre);
                            }
                        }
                    }
                }
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        guardarDatosRelevamientoStore(){
            this.$store.commit('setDatosRelevamiento',this.datosRelevamiento);
        },
        limpiarCampos(){
            this.datosRelevamiento.keywords_busqueda = this.datosRelevamientoOrig ? this.datosRelevamientoOrig.keywords_busqueda : this.producto.marca+" "+this.producto.modelo;
            this.datosRelevamiento.keywords_negadas = this.datosRelevamientoOrig ? this.datosRelevamientoOrig.keywords_negadas : '';
            let keywords_categoria = '';            
            let catAux = [];
            if(this.categoriasOrig && this.categoriasOrig.length > 0){
                for (let i = 0; i < this.categoriasOrig.length; i++) {
                    const categoria = this.categoriasOrig[i];
                    if(catAux.indexOf(categoria.nombre) == -1){
                        keywords_categoria += categoria.nombre;
                        if(i!=this.categoriasOrig.length - 1) keywords_categoria += ', ';
                        catAux.push(categoria.nombre);
                    }
                }
            }

            this.datosRelevamiento.keywords_categoria = this.datosRelevamientoOrig ? this.datosRelevamientoOrig.keywords_categoria : keywords_categoria;
            this.datosRelevamiento.precio_min = this.datosRelevamientoOrig ? this.datosRelevamientoOrig.precio_min : '';
            this.datosRelevamiento.precio_max = this.datosRelevamientoOrig ? this.this.datosRelevamientoOrig.precio_max : '';
            this.datosRelevamiento.siteId = this.datosRelevamientoOrig ? this.this.datosRelevamientoOrig.siteId : 'MLA';
        },
        async buscarItems(){
            this.loading_busqueda = true;
            this.loading_items = true;
            this.guardarDatosRelevamientoStore();
            await this.getItemsMeliByRelevamiento();
            this.loading_busqueda = false;
            this.loading_items = false;
        },
        async editProductoRelevado(){
            this.loading_busqueda = true;
            //Verifico si tengo categorias de antes
            if(this.producto.ProductoCategoriaMelis.length > 0){
                //Busco las categorias que tenia guardadas
                for (let i = 0; i < this.producto.ProductoCategoriaMelis.length; i++) {
                    const categoriaAnt = this.producto.ProductoCategoriaMelis[i];
                    //Busco los datos de esa categoria
                    let datosCategoria = await this.getDatosCategoriaMeli(categoriaAnt.category_id);
                    if(datosCategoria){
                        datosCategoria.cant_items = 0;
                        this.categorias_items_relevados.push(datosCategoria);
                        //La marco como selected
                        this.resuladoRelevamiento.categoriasSelect.push(categoriaAnt.category_id);
                    }
                }

                this.relevarAtributos();

            }else{
                console.log('Error, el producto no tiene categorias meli guardadas!')
            }
            this.loading_busqueda = false;
        },
        async getItemsMeliByRelevamiento(){
            try {
                let resource = `sites/${this.datosRelevamiento.siteId}/search`;
                let params = {
                    q: this.datosRelevamiento.keywords_busqueda,
                    sort: 'price_asc',
                    condition: 'new',
                    buying_mode: 'buy_it_now',
                    limit: 50,
                    offset: 0
                };

                if(this.datosRelevamiento.precio_min && this.datosRelevamiento.precio_min != ''){
                    params.price = '' + this.datosRelevamiento.precio_min;
                }else{
                    params.price = '0';
                }
                if(this.datosRelevamiento.precio_max && this.datosRelevamiento.precio_max != ''){
                    params.price += '-' + this.datosRelevamiento.precio_max;
                }else{
                    params.price += '-99999999';
                } 

                this.items_relevados = [];
                let finalizado = false;
                while(!finalizado){
                    let resultItems = await axios.get('https://api.mercadolibre.com/'+resource,{params});
                    let datosItems = resultItems.data;
                    if (datosItems.results.length > 0) this.items_relevados.push(...datosItems.results);
                    if ((datosItems.results.length + datosItems.paging.offset) >= datosItems.paging.total) finalizado = true;
                    else { params.offset = datosItems.paging.offset + datosItems.results.length; }
                }

                // Aplicar filtro de cuentas propias ??

                //Separo las categorias
                let ids_categorias = [];
                let items_x_categoria = [];
                this.items_relevados.forEach(item => {
                    if(ids_categorias.indexOf(item.category_id) == -1){
                        ids_categorias.push(item.category_id);
                        items_x_categoria[item.category_id] = 1;
                    }else{
                        items_x_categoria[item.category_id]++;
                    }
                });

                //Ahora Busco los datos de esas categorias
                this.categorias_items_relevados = [];
                for (let i = 0; i < ids_categorias.length; i++) {
                    const id_categoria = ids_categorias[i];
                    let datosCategoria = await this.getDatosCategoriaMeli(id_categoria);
                    datosCategoria.cant_items = items_x_categoria[id_categoria];
                    this.categorias_items_relevados.push(datosCategoria);
                }

                //Verifico si tengo categorias de antes
                if(this.producto.ProductoCategoriaMelis.length > 0){
                    for (let i = 0; i < this.producto.ProductoCategoriaMelis.length; i++) {
                        const categoriaAnt = this.producto.ProductoCategoriaMelis[i];
                        //Verifico si ya la tengo guardada
                        let categoriaAux = this.categorias_items_relevados.find( categoria => categoria.id == categoriaAnt.category_id);
                        if(!categoriaAux){
                            //No la tenia debo buscar los datos de la misma
                            let datosCategoria = await this.getDatosCategoriaMeli(categoriaAnt.category_id);
                            datosCategoria.cant_items = items_x_categoria[categoriaAnt.category_id];
                            this.categorias_items_relevados.push(datosCategoria);
                        }

                        //La marco como selected
                        this.resuladoRelevamiento.categoriasSelect.push(categoriaAnt.category_id);
                    }
                }

                //Si no tengo categorias predigo algunas
                if(!this.categorias_items_relevados.length){
                    let categoriasPredict = await this.predecirCategoriaMeli();
                    categoriasPredict.forEach(async (categoria) => {
                        let datosCategoria = await this.getDatosCategoriaMeli(categoria.category_id);
                        datosCategoria.cant_items = 0;
                        this.categorias_items_relevados.push(datosCategoria);
                    });
                }
                
                this.categorias_items_relevados.sort((a,b) => (a.cant_items < b.cant_items) ? 1 : ((b.cant_items < a.cant_items) ? -1 : 0))

                return true;
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al obtener Items de Meli';
                this.showErrorModal(message);
                return false;
            }
        },
        async getDatosCategoriaMeli(id_categoria){
            try {
                let resultCategoria = await axios.get('https://api.mercadolibre.com/categories/'+id_categoria);
                return resultCategoria.data;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        strCategoriaFromRoot(path_from_root){
            let result = '';
            path_from_root.forEach(element => {
                result = result + element.name;
                if(path_from_root[path_from_root.length-1] != element){
                    result = result + ' / ';
                }
            });
            return result;
        },
        toggleSelectAllItems(){
            if(!this.filtrosRelevamiento.flagSelectAllItems){
                this.itemsFiltrados.forEach(element => {
                    this.resuladoRelevamiento.itemsSelect.push(element.id);
                });
                this.filtrosRelevamiento.flagSelectAllItems = true;
            }else{
                this.resuladoRelevamiento.itemsSelect = [];
                this.filtrosRelevamiento.flagSelectAllItems = false;
            }
        },
        toggleFiltroKeywords(){
            this.filtrosRelevamiento.flagFiltroKeywords = !this.filtrosRelevamiento.flagFiltroKeywords;
            this.resuladoRelevamiento.itemsSelect = [];
            this.filtrosRelevamiento.flagSelectAllItems = false;
        },
        toggleFiltroKeywordsNeg(){
            this.filtrosRelevamiento.flagFiltroKeywordsNeg = !this.filtrosRelevamiento.flagFiltroKeywordsNeg;
            this.resuladoRelevamiento.itemsSelect = [];
            this.filtrosRelevamiento.flagSelectAllItems = false;
        },
        toggleFiltroPrecio(){
            this.filtrosRelevamiento.flagFiltroPrecio = !this.filtrosRelevamiento.flagFiltroPrecio;
            this.resuladoRelevamiento.itemsSelect = [];
            this.filtrosRelevamiento.flagSelectAllItems = false;
        },
        toggleSelectedItem(idItem){
            let index = this.resuladoRelevamiento.itemsSelect.indexOf(idItem);
            if(index == -1) this.resuladoRelevamiento.itemsSelect.push(idItem);
            else this.resuladoRelevamiento.itemsSelect.splice(index, 1);
        },
        separarNickVendedor(permalink){
            if(permalink){
                let partes = permalink.split('/');
                return partes[partes.length-1];
            }else{
                return 'N/A';
            }
            
        },
        reputacionVendedor(seller_reputation){
            let repAux = seller_reputation ? seller_reputation.level_id : null; 
            let result = '<b>Reputación: </b>';
            switch (repAux) {
                case '5_green': result += '<span class="badge" style="background-color: #31B93C !important;">Green</span>'; break;
                case '4_ligth-green': result += '<span class="badge" style="background-color: #BAFF20 !important;">L.Green</span>'; break;
                case '3_yellow': result += '<span class="badge" style="background-color: #FFF044 !important;">Yellow</span>'; break;
                case '2_orange': result += '<span class="badge" style="background-color: #FFB657 !important;">Orange</span>'; break;
                case '1_red': result += '<span class="badge" style="background-color: #FF605A !important;">Red</span>'; break;
                default: result += '<span class="badge bg-secondary">N/A</span>'; break;
            }

            return result;
        },
        async relevarAtributos(){

            this.guardarDatosRelevamientoStore();


            this.categorias_items_relevados.forEach(categoria => {
                if(this.resuladoRelevamiento.categoriasSelect.indexOf(categoria.id) != -1)
                this.resuladoRelevamiento.categoriasDatos.push({
                    id_categoria: categoria.id,
                    name: categoria.name,
                    path: this.strCategoriaFromRoot(categoria.path_from_root),
                });
            });

            this.$store.commit('setResuladoRelevamiento',this.resuladoRelevamiento);

            try {
                await this.$http.post(`producto/${this.id_producto}/relevar/keywords`,this.datosRelevamiento, {headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });

            } catch (error) {
                console.log(error);
                let message = error.message || error.err.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message);
                return false;
            }
            this.$router.push({name: 'relevarAttrsProducto', params: {id: this.id_producto}});

        },
        async predecirCategoriaMeli(){
            try {
                let limit = 5;
                let resultCategoria = await axios.get(`https://api.mercadolibre.com/sites/MLA/domain_discovery/search?limit=${limit}&q=${this.datosRelevamiento.keywords_categoria}`);
                console.log('Predictor categoria:',resultCategoria.data);
                return resultCategoria.data;
            } catch (error) {
                let message = error.message || error.body.err.message ||'Al predecir categoria';
                this.showErrorModal(message);
                return false;
            }
        }

    },
    async beforeMount() {
        this.id_producto = this.$route.params.id || null;
        this.loading = true;
        await this.getProducto();
        await this.getCategoriasProducto();
        await this.getIdsCuentasMeli();
        await this.getKeywordsProducto();
        this.loading = false;
    },
}
</script>

<style scoped>
    .item-selected{
        background-color: #aac9e3 !important;
    }
</style>