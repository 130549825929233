<template>
    <carousel scrollPerPage="true" autoplay="false" perPage="1" class="p-4 text-center">
        <slide v-for="(datosImg,index) in arrImgs" :key="index">
            <img :src="datosImg.path" style="width: 300px; max-width: 100%;">
        </slide>
    </carousel>
</template>

<script>
    export default {
        props: ['arrImgs'],
        beforeMount(){
            console.log(this.arrImgs);
        }
    };
</script>

<style scoped>
</style>