<template>
  <div class="container m-auto pt-4">
      <div class="text-center mb-4">
        <img :src="ImgEmpresa" alt="Imagen Empresa" >
      </div>

      <div>
        <b-table striped hover :items="tablaRelByUser.items" :fields="tablaRelByUser.fields"></b-table>
      </div>
      
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      ImgEmpresa: require('../../assets/img/therockstore.png'),
      today: new Date(),
      tablaRelByUser: {
        fields: [
          {
            key: 'nickname',
            label: 'Usuario'
          },
          {
            key: 'total',
            label: 'Cantidad total'
          }
          ,
          {
            key: 'mensual',
            label: `Cantidad Mensual`
          }
        ],
        items:[]
      }
    }
  },
  methods:{
    showErrorModal(error){
        this.$swal.fire({
            icon: 'error',
            title: "ERROR!",
            text: error,
            timer: 5500
        })
    },
    async getRelevamientosPorUsuario(){
        try {
            let response = await this.$http.get('producto/reportes/relevamiento/usuarios', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
            if (!response.body.success) throw new Error('Al obtener reporte relevamiento x usuario');

            let result = response.body.data;
            let total = 0;
            let mensual = 0;

            for (let i = 0; i < result.length; i++) {
                const element = result[i];
                if(element.total) total = total + parseInt(element.total);
                if(element.mensual) mensual = mensual + parseInt(element.mensual);
                this.tablaRelByUser.items.push({
                  nickname: element.nickname,
                  total: element.total,
                  mensual: element.mensual,
                });
            }

            this.tablaRelByUser.items.push({
              nickname: 'Totales',
              total,
              mensual
            });

        } catch (error) {
            let message = error.message || error.body.err.message ||'Al conectar con el servidor';
            this.showErrorModal(message)
        }
    },
  },
  computed:{
    ...mapGetters([
      'getUsuario',
      'getToken',
      'getPermisos',
    ])
  },
  async beforeMount(){
      this.tablaRelByUser.fields[2].label = `Cantidad Mensual (${this.today.getMonth() + 1}/${this.today.getFullYear()})`;
      await this.getRelevamientosPorUsuario();
  }
}
</script>

<style>

</style>