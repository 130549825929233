import { render, staticRenderFns } from "./RelevarAttrsProducto.vue?vue&type=template&id=91e49dee&scoped=true&"
import script from "./RelevarAttrsProducto.vue?vue&type=script&lang=js&"
export * from "./RelevarAttrsProducto.vue?vue&type=script&lang=js&"
import style0 from "./RelevarAttrsProducto.vue?vue&type=style&index=0&id=91e49dee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91e49dee",
  null
  
)

export default component.exports