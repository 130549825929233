import { store } from '../../store/store';

export default (to, from, next) => {
    let permisos = JSON.parse(JSON.stringify(store.getters.getPermisos)) || false;

    let valido = true;

    if (!permisos) {
        return next({ name: 'login', params: { error: 'No tiene permisos para el sistema' } });
    }
    if (permisos) {
        switch (to.name) {
            case 'root':
                if (!permisos['HOME'] || !permisos['HOME'].V) valido = false;
                break;
            case 'home':
                if (!permisos['HOME'] || !permisos['HOME'].V) valido = false;
                break;

            default:
                break;
        }
    }

    if (!valido) {
        return next({ name: 'forbidden' });
    } else {
        return next();
    }


};