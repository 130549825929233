<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">
                    Productos <span v-if="!loading">[ {{pagination.count}} ]</span>
                </div>
                <b-button class="float-right mr-3" @click="displayFiltros = !displayFiltros" size="sm" variant="secondary">
                        Filtros
                    </b-button>
                    <div class="clearfix"></div>
                    <b-collapse id="collapse-4" v-model="displayFiltros" class="mt-4">
                        <div class="row">
                            <!-- KeyWord -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Keyword" size='sm'>
                                    <b-form-input
                                        type="text" 
                                        v-model.trim="filtros.keyword"
                                        class="form-control" 
                                        :disabled = "loading"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- Marca -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="Marca" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.marca"
                                        :options="marcas"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="">Todas</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- Sku -->
                            <div class="col-sm-12 col-lg-4 mb-3">
                                <b-input-group prepend="SKU" size='sm'>
                                    <b-form-input
                                        type="text" 
                                        v-model.trim="filtros.sku"
                                        class="form-control" 
                                        :disabled = "loading"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <!-- medidas -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Medidas" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.medidas"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- pesos -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Pesos" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.pesos"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- img -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Imagenes" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.img"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- video -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Videos" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.video"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- manual -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Manual" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.manual"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- attr -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Attr" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.attr"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- codigo -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Codigos" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.codigo"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- disponible -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Disponible" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.disponible"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- relevado -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Relevado" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.relevado"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- pendientes -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Pendiente" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.pendiente"
                                        :options="booleanOpts"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                    </b-form-select>
                                </b-input-group>
                            </div>
                            <!-- pendientes -->
                            <div class="col-sm-6 col-lg-3 mb-3">
                                <b-input-group prepend="Deseados" size='sm'>
                                    <b-form-select
                                        type="text" 
                                        v-model.trim="filtros.not_wanted"
                                        class="form-control"  
                                        :disabled = "loading"
                                    >
                                        <b-form-select-option value="false">Si</b-form-select-option>
                                        <b-form-select-option value="true">No</b-form-select-option>
                                    </b-form-select>
                                </b-input-group>
                            </div>
                                            

                            <div class="col-12">
                                <b-button class="float-right mb-2" size="sm" @click="aplicarFiltros()" variant="primary">
                                    Aplicar
                                </b-button>
                            </div>
                        </div>
                        
                    </b-collapse>

            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else class="mb-3">
                <div class="row item-row mb-2" v-for="(producto, index) in productos" :key="index">
                    <div class="col-12 col-md-2 mb-sm-2 text-center">
                        <img 
                            @click="mostrarImagenes(producto.id)"
                            :src="producto.mini_img" 
                            class="img-thumbnail pointer mb-3" 
                            :alt="producto.titulo" 
                            style="max-width: 120px;"
                        >

                        <b-dropdown 
                            text="Datos Prov." 
                            size="sm" 
                            variant="primary"
                            v-if="producto && producto.Articulos && producto.Articulos.length"
                        >
                            <b-dropdown-item 
                                v-for="(articulo, index) in producto.Articulos"
                                :key="index"
                                :href="articulo.link_item_proveedor"
                                target="_blank"
                            >
                                Articulo {{index}}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-button class="mt-3" size="sm" variant="warning" @click="refreshImagenes(producto.id)" style="padding: 0px 10px;">
                            Refresh Imgs
                        </b-button>

                    </div> 
                    <div class="row col-12 col-md-9 mb-sm-9">
                        <div class="col-12 col-md-4 datos-item">
                            <span class="mb-sm-2"><b>Marca: </b>{{ producto.marca }}</span><br>
                            <span class="mb-sm-2"><b>Modelo: </b>{{ producto.modelo }}</span><br>
                            <span class="mb-sm-2"><b>SKU: </b>{{ producto.sku }}</span><br>
                        </div>
                        <div class="col-12 col-md-5 datos-item" v-html="producto.desc_short">
                            {{ producto.desc_short }}
                        </div>
                        <div class="col-12 col-md-1" v-if="producto.ProductoFlag">
                            <b-badge :variant="producto.ProductoFlag.relevado ? 'success' : 'danger'">Relevado</b-badge> <br>
                            <b-badge 
                                v-if="!producto.ProductoFlag.relevado"
                                :variant="producto.ProductoFlag.pendiente ? 'success' : 'danger'"
                            >Pendiente</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.disponible ? 'success' : 'danger'">Disponible</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_medidas ? 'success' : 'danger'">Medidas ({{producto.ProductoFlag.count_medidas}})</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_pesos ? 'success' : 'danger'">Pesos ({{producto.ProductoFlag.count_pesos}})</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_imgs ? 'success' : 'danger'">Imgs ({{producto.ProductoFlag.count_imgs}})</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_videos ? 'success' : 'danger'">Videos ({{producto.ProductoFlag.count_videos}})</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_manual ? 'success' : 'danger'">Manual ({{producto.ProductoFlag.count_manual}})</b-badge> <br>
                            <b-badge :variant="producto.ProductoFlag.count_codigos ? 'success' : 'danger'">Codigos ({{producto.ProductoFlag.count_codigos}})</b-badge> <br>
                        </div>
                    </div>
                    <div class="col-12 col-md-1 mb-sm-1">
                        <div class="text-right">
                            <b-button 
                                variant="info"
                                v-b-tooltip.hover.left="'Relevar'"
                                :to="{name:'relevarProducto',params:{id:producto.id}}"
                                class="mb-2"
                            >
                                <b-icon icon="cloud-arrow-up"></b-icon>
                            </b-button>
                            <b-button 
                                variant="danger"
                                v-b-tooltip.hover.left="'No deseado'"
                                v-if="!producto.ProductoFlag.relevado"
                                @click="checkMarcarNotWanted(producto.id)"
                                class="mb-2"
                            >
                                <b-icon icon="archive"></b-icon>
                            </b-button>
                        </div>
                    </div>
                </div>
                
                <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.count"
                    :per-page="pagination.limit"
                    align="center"
                    first-number
                    last-number
                    @change="handlePageChange"
                ></b-pagination>

            </div>
        </b-card>
    </div>  
</template>

<script>
import ProductoImgsModal from './modals/ProductoImgsModal';

export default {
    data(){
        return {
            loading: true,
            displayFiltros : false,
            booleanOpts: [{value: 'TODO', text: "Todos"},{value: false, text: "No"},{value: true, text: "Si"}],
            marcas: [],
            filtros: {
                sku: '',
                marca: '',
                keyword: '',
                medidas: 'TODO',
                pesos: 'TODO',
                img: 'TODO',
                video: 'TODO',
                manual: 'TODO',
                attr: 'TODO',
                codigo: 'TODO',
                disponible: 'TODO',
                relevado: 'TODO',
                pendiente: 'TODO',
                not_wanted: false
            },
            pagination:{
                page: 1,
                limit: 10,
                count: 0,
            },
            productos: [],
            imagenesAux: [],
            productoSelect : null
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        checkForMensaje(route){
            let mensajes = this.$store.getters.getMensajesPag;
            console.log(mensajes);
            let mensaje = mensajes[route];
            if(mensaje.type && mensaje.msg){
                this.$swal.fire({
                    position: 'top-end',
                    icon: mensaje.type,
                    title: mensaje.msg,
                    showConfirmButton: false,
                    timer: 2500
                }).then(() => {
                    this.$store.commit('setMensajePage',{
                        route : route,
                        data: {
                            type: null,
                            msg: null,
                        }
                    });
                })
            }
        },
        async getListaProductos(){
            try {
                let query = `producto`+this.armarQuery(false);

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener productos');

                console.log('Productos: ',response.body.data);
                this.productos = response.body.data.rows;

                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getListaMarcas(){
            try {
                let response = await this.$http.get(`scraper/thomann/item/marca`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de marcas');
                this.marcas = [];
                console.log('Marcas: ',response.body.data);
                response.body.data.forEach(element => {
                    if(this.marcas.indexOf(element.marca) == -1) this.marcas.push(element.marca);
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async handlePageChange(value){
            this.loading = true;
            this.pagination.page = value;
            this.armarQuery();
            await this.getListaProductos();
            this.loading = false;
        },
        async aplicarFiltros (){
            this.loading = true;
            this.pagination.page = 1;
            await this.getListaProductos();
            this.loading = false;
        },
        armarQuery(){
            let queryParams = {};
            queryParams.page = this.pagination.page;
            queryParams.limit = this.pagination.limit;
            queryParams.not_wanted = this.filtros.not_wanted;
            if(this.filtros.sku != '')    queryParams.sku = this.filtros.sku;
            if(this.filtros.keyword != '')    queryParams.keyword = this.filtros.keyword;
            if(this.filtros.marca != '')    queryParams.marca = this.filtros.marca;
            if(this.filtros.medidas != 'TODO')    queryParams.medidas = this.filtros.medidas;
            if(this.filtros.pesos != 'TODO')    queryParams.pesos = this.filtros.pesos;
            if(this.filtros.img != 'TODO')    queryParams.img = this.filtros.img;
            if(this.filtros.video != 'TODO')    queryParams.video = this.filtros.video;
            if(this.filtros.manual != 'TODO')    queryParams.manual = this.filtros.manual;
            if(this.filtros.attr != 'TODO')    queryParams.attr = this.filtros.attr;
            if(this.filtros.codigo != 'TODO')    queryParams.codigo = this.filtros.codigo;
            if(this.filtros.disponible != 'TODO')    queryParams.disponible = this.filtros.disponible;
            if(this.filtros.relevado != 'TODO')    queryParams.relevado = this.filtros.relevado;
            if(this.filtros.pendiente != 'TODO')    queryParams.pendiente = this.filtros.pendiente;

            let query = '';
            for( var key in queryParams ) {
                var value = queryParams[key];
                if(query == '') query+= '?'+key+'='+value;
                else query+= '&'+key+'='+value;
            }
            this.$router.push({ query: Object.assign({}, {}, queryParams) }).catch(() => {});
            return query;
        },
        async mostrarImagenes(id_producto){
            try {
                let response = await this.$http.get(`producto/${id_producto}/img`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener imagenes del producto');

                console.log('Imgs: ',response.body.data);

                this.$modal.show(ProductoImgsModal, {arrImgs: response.body.data},{height:"auto"});

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        checkMarcarNotWanted(id_producto){
            this.productoSelect = id_producto;

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de enviar no deseado?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.marcarProductoNotWanted();
                }
            })
        },

        async marcarProductoNotWanted(){
            console.log('Enviar a no deseado',this.productoSelect);

            let response = await this.$http.put(`producto/${this.productoSelect}/not-wanted`,{not_wanted: true}, {headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
            if (response.body && response.body.success) {   
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Producto enviado a no deseados',
                    showConfirmButton: false,
                    timer: 2000
                })
                this.loading = true;
                await this.getListaProductos();
                this.loading = false;
            } else {
                console.log(response);
                this.showErrorModal('Error al enviar a no deseado');
            }
        },

        refreshImagenes(id_producto){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    title: "swal-title",
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger mr-3'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Esta seguro de actualizar las imgs?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.actualizarImgsProducto(id_producto);
                }
            })
        },

        async actualizarImgsProducto(id_producto){
            this.$swal.fire({
                title: 'Please Wait !',
                html: 'Actualizando ...',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    this.$swal.showLoading()
                },
            });
            let response = await this.$http.put(`producto/${id_producto}/refresh-imgs`,{not_wanted: true}, {headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
            this.$swal.close();
            if (response.body && response.body.success) {   
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Imagenes Actualizadas',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getListaProductos();
            } else {
                console.log(response);
                this.showErrorModal('Error al actualizar imagenes');
            }
        }



    },
    async beforeMount(){
        this.checkForMensaje('listProductos');

        this.pagination.page = this.$route.query.page || 1;
        this.pagination.limit = this.$route.query.limit || 10;
        this.filtros.keyword = this.$route.query.keyword || '';
        this.filtros.marca = this.$route.query.marca || '';
        this.filtros.sku = this.$route.query.sku || '';

        this.filtros.medidas = this.$route.query.medidas || 'TODO';
        this.filtros.pesos = this.$route.query.pesos || 'TODO';
        this.filtros.img = this.$route.query.img || 'TODO';
        this.filtros.video = this.$route.query.video || 'TODO';
        this.filtros.manual = this.$route.query.manual || 'TODO';
        this.filtros.attr = this.$route.query.attr || 'TODO';
        this.filtros.codigo = this.$route.query.codigo || 'TODO';
        this.filtros.disponible = this.$route.query.disponible || 'TODO';
        this.filtros.relevado = this.$route.query.relevado || 'TODO';
        this.filtros.pendiente = this.$route.query.pendiente || 'TODO';
        this.filtros.not_wanted = this.$route.query.not_wanted || false;

        this.loading = true;
        await this.getListaProductos();
        await this.getListaMarcas();
        this.loading = false;
    }
}
</script>

<style scoped>
    .item-row{
        border: 1px solid rgb(0 0 0 / 25%);
        padding: 10px 15px;
        border-radius: 5px;
    }

    .datos-item{
        font-size: small;
    }

</style>