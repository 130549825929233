<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">Thomann Links</div>

                <b-button class="float-right mr-3" @click="showModalAgregarLink()" variant="primary" size="sm">
                    <b-icon icon="plus" font-scale="1"></b-icon>
                </b-button>
            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else>
                <b-table striped hover :items="tabla.data" :fields="tabla.fields">
                    <template #cell(url)="data">
                        <a :href="data.value" target="_blank">{{data.value}}</a>
                    </template>
                    <template #cell(relevado)="data">
                        <span v-if="data.value">Listo</span>
                        <span v-else>Pendiente</span>
                    </template>
                    <template #cell(cantidad)="data">
                        <span v-if="data.value">{{data.value}}</span>
                        <span v-else>...</span>
                    </template>
                    <template #cell(updatedAt)="data">
                        <!-- <span style="font-size: small;">{{data.value | moment('DD/MM/YYYY hh:mm')}}</span> -->
                        <span style="font-size: small;">{{data.value}}</span>
                    </template>
                </b-table>
            </div>
        </b-card>
    </div>  
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            loading_force : false,
            tabla: {
                fields: [
                    {
                        key: 'url',
                        label: 'Link',
                        sortable: true
                    },
                    {
                        key: 'cantidad',
                        label: 'Cantidad',
                        sortable: true
                    },
                    {
                        key: 'relevado',
                        label: 'Estado',
                        sortable: true,
                    },
                    {
                        key: 'updatedAt',
                        label: 'Modificado',
                        sortable: true,
                    }
                ],
                data:[]
            },
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getListaLinks(){
            try {
                let response = await this.$http.get('scraper/thomann/link', { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener links');

                console.log(response.body.data);
                this.tabla.data = response.body.data;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        showModalAgregarLink(){
            this.$swal.fire({
                input: 'url',
                title: 'Ingresar Url',
                text: 'Recordar que debe setear pais argentina, moneda pesos argentinos',
                showCancelButton: true,
                confirmButtonText: 'Agregar',
                cancelButtonText: 'Cancelar',
            }).then((data) => {
                if (data.isConfirmed) {
                    this.agregarLink(data.value);
                }
            })
        },
        async agregarLink(url){
            try {
                let response = await this.$http.post('scraper/thomann/link',{url}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al agregar link');

                await this.getListaLinks();
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'El link fue agregado',
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
    },
    async beforeMount(){
        this.loading = true;
        await this.getListaLinks();
        this.loading = false;
    }
}
</script>

<style>

</style>