<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <b-card header-tag="header" >
                <template #header>
                    <div>
                        <b-button class="btn btn-danger btn-sm float-left mr-3" @click="$router.go(-1)">
                            <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                        </b-button>
                        <div class="float-left table-panel-title mr-5">Proveedores</div>
                    </div>
                    <div class="clearfix"></div>
                </template>
                
                <div class="row">
                    <!-- Thomman -->
                    <div class="col-4">
                        <div class="card text-center pointer">                       
                            <img :src="ImgThomann" class="card-img-top" alt="Image Thomann">
                            <div class="card-body">
                                <h5 class="card-title">Thomann</h5>
                            </div>
                            <div class="card-footer">
                                <router-link :to="{name: 'thomannListaLinks'}">
                                    <button type="button" class="btn btn-primary btn-sm mr-1">Links</button>
                                </router-link>
                                <router-link :to="{name: 'thomannListaItems'}">
                                    <button type="button" class="btn btn-primary btn-sm mr-1">Items</button>
                                </router-link>
                                <router-link :to="{name: 'thomannDiccionario'}">
                                    <button type="button" class="btn btn-primary btn-sm">Diccionario</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </b-card>

        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            ImgThomann: require('../../assets/img/thomann-logo.png'),
            loading: true,
            proveedores: [],
            paises: [],
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
    },
    async beforeMount(){
        this.loading = false;
    }
}
</script>

<style scoped>
    .card-img, .card-img-top {
        height: 200px;
        width: 200px;
        margin: auto;
    }
</style>